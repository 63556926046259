/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { type JSXElementConstructor, ComponentProps } from 'react';
import { sinceDateFormatter } from 'utils/dates';

import styles from './styles.module.css';

interface IProps<TTag extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> extends ComponentProps<'div'> {
	titleTag?: TTag;
	date: string | number | Date;
	title?: string;
	dateFormatter?: (date: string | number | Date) => string;
}

const RegistryDateBadge = <TTag extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>({
	titleTag,
	className,
	title = 'Дата реєстрації',
	date,
	dateFormatter = sinceDateFormatter,
	...restProps
}: IProps<TTag>) => {
	const Tag = titleTag ?? 'h2';
	const formattedDate = dateFormatter(date);

	return (
		<div data-registry-date-badge className={clsx(styles.registryDateBadge, className)} {...restProps}>
			<Tag className="color-grey-700">{title}</Tag>

			<p className={clsx('color-grey-700', styles.time)}>
				<time dateTime={date.toString()}>{formattedDate}</time>
			</p>
		</div>
	);
};

export default RegistryDateBadge;
