import clsx from 'clsx';
import SpinnerV2 from 'components/Spinner-v2';
import VirtualizedList from 'components/VirtualizedList';
import { useStackState } from 'hooks/useStackState';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ReactComponent as MagnifyGlassIcon } from 'static/images/searchInMenu.svg';
import { useGetBrandsFiltersQuery, useGetCategoriesFiltersQuery, useLazyGetCategoriesFiltersQuery } from 'store/reducers/orders/ordersSliceApi';

import QueuedFilterItem from '../../ClientsFilter/QueuedFilterItem';
import FilterHeader from './FilterHeader';
import FilterIcon from './FilterIcon';
import { StateMachineModel, useStateMachine } from './hooks/useStateMachine';
import { FilterSearchParamsKey } from './lib/const';
import { buildFiltersStackLikeStructure, hydrate } from './lib/utils';
import styles from './styles.module.css';
import type { IProps } from './types';

const ActiveFilterIcon = () => <FilterIcon isActive />;
const InActiveFilterIcon = () => <FilterIcon />;

const ProductFilter: React.FC<IProps> = ({ className, ...restProps }) => {
	const [appliedCategoriesList, appliedCategoriesStack] = useStackState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [filterBrand, setFilterBrands] = useState('');

	const stateMachine = useStateMachine(hydrate(searchParams));
	const isStackHydrated = useRef(false);

	const categoryQueryParams = searchParams.get(FilterSearchParamsKey.Category)
		? `${FilterSearchParamsKey.Category}=${searchParams.get(FilterSearchParamsKey.Category)}`
		: '';

	const needReHydrateCategoriesQueue = searchParams.get(FilterSearchParamsKey.ReHydrateCat) === 'true';
	const { data: brandFilterList, ...brandRequest } = useGetBrandsFiltersQuery(undefined);
	const { data: categoryFilterList, ...categoryRequest } = useGetCategoriesFiltersQuery(categoryQueryParams);
	const [loadCategoriesAsync, { ...lazyCategoryRequest }] = useLazyGetCategoriesFiltersQuery();

	useEffect(() => {
		(async () => {
			if (needReHydrateCategoriesQueue) {
				const [initialCategory] = await loadCategoriesAsync(
					`${FilterSearchParamsKey.Category}=${searchParams.get(FilterSearchParamsKey.Category)}`,
				).unwrap();

				if (initialCategory?.parent) {
					const parents = buildFiltersStackLikeStructure(initialCategory);

					const categories = parents.map((item, index) => ({ index, item }));
					const newSearchParams = new URLSearchParams(searchParams);
					newSearchParams.delete(FilterSearchParamsKey.ReHydrateCat);

					setSearchParams(newSearchParams);
					appliedCategoriesStack.pushBulk(categories, { clearBefore: true });
					stateMachine.set('category:applied');
				}
			}
		})();
	}, [needReHydrateCategoriesQueue, categoryFilterList, searchParams]);

	useEffect(() => {
		if (categoryFilterList && !isStackHydrated.current) {
			isStackHydrated.current = true;
			const [initialCategory] = categoryFilterList;

			if (initialCategory?.parent) {
				const parents = buildFiltersStackLikeStructure(initialCategory);

				const categories = parents.map((item, index) => ({ index, item }));
				appliedCategoriesStack.pushBulk(categories);
			}
		}
	}, [categoryFilterList]);

	const onGoBackFilter = (key: string) => async () => {
		const newSearchParams = new URLSearchParams(searchParams);

		if (newSearchParams.get(FilterSearchParamsKey.ProductId)) {
			newSearchParams.delete(FilterSearchParamsKey.ProductId);
		}

		newSearchParams.delete(key);
		setSearchParams(newSearchParams);

		stateMachine.set('idle');
		appliedCategoriesStack.clear();
	};

	const hideSubmenu = () => {
		stateMachine.set('idle');
	};
	const switchStateHandler = (key: StateMachineModel) => () => stateMachine.set(key);

	const isLast = Boolean(categoryFilterList?.[0]?.isLast);
	const hasAvailableCategoryFilterList =
		isStackHydrated.current &&
		!categoryRequest.isFetching &&
		!lazyCategoryRequest.isFetching &&
		categoryRequest.isSuccess &&
		!categoryRequest.isLoading &&
		categoryFilterList?.length > 0 &&
		!isLast;

	const hasAvailableBrandFilters = brandFilterList?.length > 0;
	const canShowBrandFilterList = (stateMachine.isIdle || stateMachine.isBrandMenuOpened) && hasAvailableBrandFilters;
	const canShowAppliedCategoryFilterList = stateMachine.isCategoryFilterApplied || stateMachine.isBrandFilterApplied;
	const canShowFilteringInput = stateMachine.isIdle || stateMachine.isBrandMenuOpened;
	const canShowAppliedCategoryFilterHeader = !stateMachine.isIdle && !stateMachine.isBrandMenuOpened;
	const isCategoriesLoading =
		categoryRequest.isLoading || categoryRequest.isFetching || lazyCategoryRequest.isLoading || lazyCategoryRequest.isFetching;
	const isBrandsLoading = brandRequest.isLoading || brandRequest.isFetching;

	const filteredBrandFilterList = useMemo(() => {
		if (!filterBrand.trim()) return brandFilterList ?? [];

		return brandFilterList?.filter((filter) => filter?.title?.toLowerCase()?.includes(filterBrand.trim().toLowerCase())) ?? [];
	}, [filterBrand, brandFilterList]);

	return (
		<div data-product-filter className={clsx(styles.wrapper, className)} {...restProps}>
			<div data-sticky-filter className={clsx(styles.stickyContainer, { [styles.paddingOffset]: stateMachine.isIdle })}>
				{stateMachine.isIdle && (
					<div className={styles.rootControlsWrapper}>
						<QueuedFilterItem
							isActive
							title="Категорії"
							onClick={switchStateHandler('category:applied')}
							onUnselect={hideSubmenu}
							icon={ActiveFilterIcon}
						/>
						<QueuedFilterItem
							isActive
							onClick={switchStateHandler('brand:opened')}
							onUnselect={hideSubmenu}
							title="Виробники"
							icon={ActiveFilterIcon}
						/>
					</div>
				)}
				{canShowAppliedCategoryFilterHeader && (
					<FilterHeader
						title={appliedCategoriesList.length > 0 ? 'Підкатегорія' : 'Категорія'}
						onGoBackClick={onGoBackFilter(FilterSearchParamsKey.Category)}
						className={clsx(styles.header)}
					/>
				)}
				{stateMachine.isBrandMenuOpened && (
					<FilterHeader title="Виробник" onGoBackClick={onGoBackFilter(FilterSearchParamsKey.Brand)} className={clsx(styles.header)} />
				)}

				{canShowAppliedCategoryFilterList && (
					<ul className={styles.queueItemsList}>
						{appliedCategoriesList.map((category) => (
							<QueuedFilterItem
								isActive
								title={category?.item?.title}
								key={category.item.id}
								icon={ActiveFilterIcon}
								onUnselect={() => {
									const newSearchParams = new URLSearchParams(searchParams);
									const categoryId = category.item?.parent?.id;

									if (newSearchParams.get(FilterSearchParamsKey.ProductId)) {
										newSearchParams.delete(FilterSearchParamsKey.ProductId);
									}

									if (!categoryId) {
										newSearchParams.delete(FilterSearchParamsKey.Category);
									} else {
										newSearchParams.set(FilterSearchParamsKey.Category, category.item?.parent?.id);
									}

									appliedCategoriesStack.pop(category.index);
									setSearchParams(newSearchParams);
								}}
							/>
						))}
						{hasAvailableCategoryFilterList &&
							categoryFilterList.map((category) => {
								// !!TEMP till backend removes those cats itself
								if (category.title.startsWith('!')) return null;
								if (category.title.includes('Touch')) return null;
								if (category.title.includes('VarioClick')) return null;

								return (
									<QueuedFilterItem
										title={category.title}
										onUnselect={() => {}}
										key={category.id}
										onClick={() => {
											const newSearchParams = new URLSearchParams(searchParams);

											if (newSearchParams.get(FilterSearchParamsKey.ProductId)) {
												newSearchParams.delete(FilterSearchParamsKey.ProductId);
											}

											newSearchParams.set(FilterSearchParamsKey.Category, category.id);
											appliedCategoriesStack.push({ index: appliedCategoriesList.length, item: category });
											setSearchParams(newSearchParams);
										}}
										icon={InActiveFilterIcon}
									/>
								);
							})}

						{isCategoriesLoading && (
							<div className={styles.spinner}>
								<SpinnerV2 />
							</div>
						)}
					</ul>
				)}

				{canShowFilteringInput && (
					<div className={styles.productSearchInputWrapper}>
						<label htmlFor="product-search" className="visually-hidden">
							Шукати товар
						</label>

						<MagnifyGlassIcon className={styles.icon} />

						<input
							className={clsx('text-sm-regular', styles.input)}
							value={filterBrand}
							onChange={(e) => setFilterBrands(e.currentTarget.value)}
							id="product-search"
							type="text"
							placeholder="Почніть писати бренд"
						/>
					</div>
				)}

				{canShowBrandFilterList && (
					<div className={clsx(styles.listsWrapper, styles.brandsList)}>
						<AutoSizer>
							{({ height, width }) => {
								return (
									<VirtualizedList
										items={filteredBrandFilterList}
										height={height}
										width={width}
										itemSize={50 + 8}
										renderItem={({ item: brand, index, style }) => {
											const handleBrandClick = () => {
												const newSearchParams = new URLSearchParams(searchParams);

												if (newSearchParams.get(FilterSearchParamsKey.ProductId)) {
													newSearchParams.delete(FilterSearchParamsKey.ProductId);
												}

												newSearchParams.set(FilterSearchParamsKey.Brand, brand.id);
												setSearchParams(newSearchParams);
												stateMachine.set('brand:applied');
											};
											const isActive = searchParams.get(FilterSearchParamsKey.Brand)?.toLowerCase() === brand.id.toLowerCase();

											return (
												<div style={{ ...style, paddingBottom: '8px' }}>
													<QueuedFilterItem
														isActive={isActive}
														className={styles.brandFilterItem}
														key={brand.id + index}
														title={brand.title}
														onClick={handleBrandClick}
														icon={InActiveFilterIcon}
														onUnselect={() => {
															const newSearchParams = new URLSearchParams(searchParams);
															if (newSearchParams.get(FilterSearchParamsKey.ProductId)) {
																newSearchParams.delete(FilterSearchParamsKey.ProductId);
															}
															newSearchParams.delete(FilterSearchParamsKey.Brand);

															setSearchParams(newSearchParams);
															stateMachine.set('idle');
														}}
													/>
												</div>
											);
										}}
									/>
								);
							}}
						</AutoSizer>

						{isBrandsLoading && (
							<div className={styles.spinner}>
								<SpinnerV2 />
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductFilter;
