import clsx from 'clsx';
import React, { useRef } from 'react';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { uuid } from 'utils/shared';
import { isFunction, isObject } from 'utils/type-guards';

import styles from './styles.module.css';
import type { IActionSlot, IProps } from './types';

const emptyObject = {};

const FormField = <TComponentProps, TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	actionSlot = emptyObject,
	shouldUnregister = false,
	fieldClassName,
	defaultValue,
	children,
	type = 'text',
	component,
	control,
	label,
	name,
	hiddenLabel,
	hint,
	transform,
	renderLabel,
	disabled = false,
	...restProps
}: TComponentProps & IProps & UseControllerProps<TFieldValues, TName>) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const controller = useController({ name, defaultValue, shouldUnregister, control });
	const {
		field,
		fieldState: { error },
	} = controller;
	const { value, onChange, ...restField } = field;
	const resolvedName = !!hiddenLabel ? uuid() : name;

	const componentElement = isFunction(component) ? component({ ...field, ...restProps, disabled, name: resolvedName }) : null;
	const Component = component;
	const hasError = !!error?.message;

	const actionSlotComponent =
		isObject<IActionSlot>(actionSlot) && 'content' in actionSlot ? (
			<button type="button" data-disabled={disabled} onClick={actionSlot?.onActionPerform} className={styles.actionSlot}>
				{actionSlot.content}
			</button>
		) : Object.keys(actionSlot ?? {}).length === 0 ? null : (
			(actionSlot as React.ReactNode)
		);

	const handleOnChange = (newValue: unknown) => {
		const transformedValue = transform ? transform(newValue) : newValue;

		onChange(transformedValue);
	};

	const labelClassNames = clsx(styles.label, 'text-sm-medium', { 'visually-hidden static': !!hiddenLabel });

	const labelEl = (
		<label htmlFor={resolvedName} className={labelClassNames}>
			{label}
		</label>
	);

	return (
		<div data-form-field-wrapper data-disabled={disabled} className={clsx(styles.inputWrapper, fieldClassName)}>
			{!!label && !!!renderLabel && labelEl}
			{!!renderLabel && renderLabel(labelClassNames)}

			<div data-field-inner-wrapper ref={wrapperRef} className={styles.inputInnerWrapper}>
				{componentElement || (
					<Component
						id={resolvedName}
						value={value}
						setValue={handleOnChange}
						type={type}
						{...restField}
						{...restProps}
						disabled={disabled}
						name={resolvedName}
					/>
				)}

				{children}

				{actionSlotComponent}

				{hasError && <strong className={clsx('error-text', styles.errorMessage)}>{error.message}</strong>}
				{!!hint && !error && <strong className={clsx('text-sm-regular', styles.hint)}>{hint}</strong>}
			</div>
		</div>
	);
};

export default FormField;
