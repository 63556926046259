import type { MultipleStringControllerProps } from 'components/SearchParamsController/MultipleStringController';
import MultipleStringController from 'components/SearchParamsController/MultipleStringController';
import type { Option } from 'models/common/options';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Chart } from 'static/images/chart.svg';

import SearchableFilter from '../components/SearchableFilter';
import SingleFilterWrapper from '../components/SingleFilterWrapper';
import styles from '../style.module.css';

type BySegmentFilterProps = Pick<MultipleStringControllerProps, 'queryKey'> & {
	options: Partial<Option>[];
	title?: string;
	icon?: React.ReactElement;
	placeholder?: string;
};

const BySegmentFilter: React.FC<BySegmentFilterProps> = ({
	options,
	queryKey,
	title = 'Сегмент',
	icon = <Chart />,
	placeholder = 'Введіть назву сегмента',
}) => {
	const [searchParams] = useSearchParams();
	const isExpanded = searchParams.has(queryKey);

	return (
		<MultipleStringController
			queryKey={queryKey}
			render={({ value, onChange, activeFiltersCount, indeterminateFiltersCount }) => {
				return (
					<SingleFilterWrapper
						activeFiltersCount={activeFiltersCount}
						indeterminateFiltersCount={indeterminateFiltersCount}
						expanded={isExpanded}
						title={title}
						{...(!!icon && { icon })}
					>
						<div className={styles.checkboxGroupWrapper}>
							<SearchableFilter value={value} onChange={onChange} options={options as Required<Option>[]} placeholder={placeholder} />
						</div>
					</SingleFilterWrapper>
				);
			}}
		/>
	);
};

BySegmentFilter.displayName = MultipleStringController.displayName;

export default BySegmentFilter;
