import { format, isValid } from 'date-fns';

export const sinceDateFormatter = (date: Date | string | number): string => {
	const dateString = new Date(date).toLocaleDateString('uk-UA');
	return `з ${dateString}`;
};

export const formatAndExtractDateTime = (date: Date | string | number) => {
	const dt = new Date(date);
	const newDate = isValid(dt) ? new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000) : new Date();

	const dateOnly = format(newDate, 'dd.MM.yyyy');
	const timeOnly = format(newDate, 'HH:mm');

	return [dateOnly, timeOnly] as const;
};
