import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
	MakeBusinessOfferRequestDTO,
	MakeBusinessOfferResult,
	MakeBusinessOfferResultDTO,
	transformToMakeBusinessOfferResult,
} from 'models/business-offer';
import { usersQueryKeys } from 'services/queryKeys';

import apiClient, { baseApiUrl } from '../auth/apiClient';

export const businessOfferSliceApi = createApi({
	reducerPath: 'businessOffer',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		makeBusinessOffer: builder.mutation<MakeBusinessOfferResult, MakeBusinessOfferRequestDTO>({
			queryFn: async (dto) => {
				try {
					const response = await apiClient.post<{ data: MakeBusinessOfferResultDTO }>('/business-offers', dto);

					if (response.status < 200 && response.status >= 300) {
						throw new Error(response.statusText);
					}

					const businessOffer = transformToMakeBusinessOfferResult(response.data.data);

					return {
						data: businessOffer,
					};
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const { useMakeBusinessOfferMutation } = businessOfferSliceApi;
