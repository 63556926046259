import clsx from 'clsx';
import Button from 'components/Button';
import React, { ChangeEvent, MouseEvent, useRef } from 'react';
import CameraIcon from 'static/images/camera-plus.svg';
import { supportsCapture } from 'utils/devices';
import { blobToBase64 } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps } from './types';

const CameraButton: React.FC<IProps> = ({ onClick, onSnapshot, className, nativeDeviceOnly = true, ...restProps }) => {
	const deviceCameraRef = useRef<HTMLInputElement>(null);
	const hasCaptureSupport = supportsCapture();

	if (nativeDeviceOnly && !hasCaptureSupport) {
		return null;
	}

	const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (onClick) onClick();
	};

	const handleDeviceCameraClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (onClick) onClick();

		deviceCameraRef.current?.click();
	};

	const handleCapture = async (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target;

		if (target.files) {
			if (target.files.length !== 0) {
				const file = target.files[0];
				const base64Img = await blobToBase64(file);

				onSnapshot?.(base64Img);
			}
		}
	};

	return (
		<>
			<Button
				id="camera-button"
				variant="rounded"
				background="var(--primary-600)"
				icon={CameraIcon}
				onClick={hasCaptureSupport ? handleDeviceCameraClick : handleButtonClick}
				text="Зробити фото"
				className={clsx(styles.cameraButton, className)}
				{...restProps}
			/>

			<input ref={deviceCameraRef} onChange={handleCapture} className={styles.deviceCamera} accept="image/*" type="file" capture="user" />
		</>
	);
};

export default CameraButton;
