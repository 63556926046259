import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RequestResult } from 'models/api';
import { CreateFilterTemplate, FilterTemplate, GetFilterTemplatePayload } from 'models/filter-template';
import { filterTemplatesQueryKeys } from 'services/queryKeys';
import { uuid } from 'utils/shared';

import apiClient, { baseApiUrl } from '../auth/apiClient';

export const filterTemplatesSliceApi = createApi({
	reducerPath: 'filterTemplates',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: [filterTemplatesQueryKeys.filterTemplates()],
	endpoints: (builder) => ({
		getFilterTemplate: builder.query<FilterTemplate[], GetFilterTemplatePayload>({
			queryFn: async ({ userId, type }) => {
				try {
					const response = await apiClient.get<FilterTemplate[]>(`/filter-templates?userId=${userId}&type=${type}`);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data,
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) =>
				result
					? [filterTemplatesQueryKeys.filterTemplates(), ...result.map((template) => filterTemplatesQueryKeys.filterTemplate(template.id))]
					: [],
		}),
		createFilterTemplate: builder.mutation<FilterTemplate, CreateFilterTemplate>({
			queryFn: async (dto) => {
				try {
					const response = await apiClient.post<RequestResult<FilterTemplate>>('/filter-templates', dto);

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data.data,
					};
				} catch (error) {
					throw error;
				}
			},
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				const cacheKey = { userId: dto.userId, type: dto.type };
				const tempId = uuid();

				const patchResult = dispatch(
					filterTemplatesSliceApi.util.updateQueryData('getFilterTemplate', cacheKey, (draft) => {
						draft.unshift({ ...dto, id: tempId });
					}),
				);

				try {
					const { data: createdTemplate } = await queryFulfilled;
					dispatch(
						filterTemplatesSliceApi.util.updateQueryData('getFilterTemplate', cacheKey, (draft) => {
							const index = draft.findIndex((template) => template.id === tempId);

							if (index !== -1) {
								const entity = draft[index];

								const res = Object.assign(entity, createdTemplate);
								draft[index] = res;
							}

							return draft;
						}),
					);
				} catch {
					patchResult.undo();
				}
			},
		}),
		deleteFilterTemplate: builder.mutation<void, GetFilterTemplatePayload & { templateId: string }>({
			queryFn: async ({ templateId }) => {
				try {
					const response = await apiClient.delete<FilterTemplate>(`/filter-templates/${templateId}`);

					if (response.status !== 204) {
						throw new Error(response.statusText);
					}

					return {
						data: undefined,
					};
				} catch (error) {
					throw error;
				}
			},
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					filterTemplatesSliceApi.util.updateQueryData('getFilterTemplate', { userId: dto.userId, type: dto.type }, (draft) => {
						return draft.filter((template) => template.id !== dto.templateId);
					}),
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
	}),
});

export const { useGetFilterTemplateQuery, useCreateFilterTemplateMutation, useDeleteFilterTemplateMutation } = filterTemplatesSliceApi;
