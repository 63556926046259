import { ROUTES_URLS } from 'const';
import { CanUser } from 'contexts/AbilityContext';
import React from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = React.ComponentProps<typeof CanUser>;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, ...props }) => {
	return (
		<CanUser passThrough {...props}>
			{(can) => {
				return can ? <>{children}</> : <Navigate replace to={ROUTES_URLS.HOME} />;
			}}
		</CanUser>
	);
};

export default ProtectedRoute;
