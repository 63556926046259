import clsx from 'clsx';
import type { MainSearchAutocompleteProps, RenderPropsStrategyItemParams, TransformedSearchResultOption } from 'components/MainSearch/lib/types';
import { useBoolean } from 'hooks/useBoolean';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { forwardRef, useMemo } from 'react';

import { useMainSearch } from '../../Provider';
import { MainSearchInput } from '../MainSearchInput';
import { SearchResultList } from '../SearchResultList';
import { SearchResultListItem } from '../SearchResultListItem';

const DefaultRender = ({ item, onClick }: RenderPropsStrategyItemParams<TransformedSearchResultOption> & { onClick?: () => void }) => {
	return (
		<SearchResultListItem key={item.value} onClick={onClick}>
			{item.label}
		</SearchResultListItem>
	);
};

const itemsFallback = [];

export const InputComponent = <T,>(
	{
		placeholder,
		disabled,
		className,
		render,
		onSelect,
		emptyState = 'Жодного клієнта не знайдено. Спробуйте інший запит',
		useLoadAsyncDataQuery,
		queryKey = '',
		transformQueryResult,
	}: MainSearchAutocompleteProps<T>,
	ref: React.ForwardedRef<HTMLInputElement>,
) => {
	const dropdown = useBoolean();
	const { query } = useMainSearch();
	const { data: items = itemsFallback, isLoading, isFetching } = useLoadAsyncDataQuery([query, queryKey]);

	const resolvedRender = render || DefaultRender;
	const isRequestProcessing = isLoading || isFetching;
	const resolvedItems = useMemo(() => (transformQueryResult ? items.map(transformQueryResult) : items), [transformQueryResult, items]);

	const handleSelect = (option: TransformedSearchResultOption) => {
		onSelect(option);
		dropdown.close();
	};

	const wrapperRef = useClickOutside<HTMLDivElement>(dropdown.close);

	return (
		<div ref={wrapperRef} className={clsx(className)}>
			<MainSearchInput ref={ref} placeholder={placeholder} disabled={disabled} onFocus={dropdown.open} />

			{dropdown.isOn && (
				<SearchResultList
					onSelect={handleSelect}
					items={resolvedItems}
					emptyState={emptyState}
					render={resolvedRender}
					isLoading={isRequestProcessing}
				/>
			)}
		</div>
	);
};

export const MainSearchAutocomplete = forwardRef(InputComponent) as <T>(
	props: MainSearchAutocompleteProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => JSX.Element;
