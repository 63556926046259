import clsx from 'clsx';
import PriceModifier from 'components/PriceModifier';
import Spinner from 'components/Spinner';
import { VisibilityModel } from 'components/Table/lib/table/types/table';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useBoolean } from 'hooks/useBoolean';
import { useClickOutside } from 'hooks/useClickOutside';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { localStorageService } from 'services/localStorageService';
import { useGetProductLastPricesMutation } from 'store/reducers/orders/ordersSliceApi';
import { isString } from 'utils/type-guards';

import { useTypedOrderControllerFromContext } from '../OrderController';
import { priceTypeButtonList, priceTypeColorMap } from './config';
import styles from './styles.module.css';
import type { IProps } from './types';

const PriceTypesSelect: React.FC<IProps> = ({
	children,
	className,
	onVisibilityModelChange,
	disableAll = false,
	justify = 'space-between',
	hydrateStateKey,
	suborderIndex,
}) => {
	const [activeIds, setActiveIds] = useState<string[]>(hydrateState(hydrateStateKey)());
	const dialogue = useOrderAlertDialogue();
	const dropdown = useBoolean();
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-start' });
	const { getValues } = useTypedOrderControllerFromContext();

	const suborder = getValues('suborders')?.[suborderIndex];
	const client = suborder?.data?.client;
	const contract = suborder?.data?.contract;

	const [fetchData, { reset, isLoading }] = useGetProductLastPricesMutation({
		fixedCacheKey: `${client?.value}:${contract?.value}`,
	});

	const types = priceTypeButtonList.map(({ label, id, isLongRequest }, index) => ({
		...priceTypeColorMap[index],
		modifierDisplayValue: label,
		id,
		isLongRequest,
	}));

	const toggleColumns = (id: string) => {
		setActiveIds((prev) => (prev.includes(id) ? prev.filter((activeId) => activeId !== id) : [...prev, id]));

		onVisibilityModelChange((prevModel) => ({ ...prevModel, [id]: !prevModel?.[id] }));
	};

	const handleSelectionSafe = (id: string, isLongRequest: boolean) => {
		if (disableAll) return;

		if (isLongRequest) {
			dialogue.open('showLastPriceColumn', {
				onSubmit: async () => {
					try {
						await fetchData({ clientId: client?.value, contractId: contract?.value }).unwrap();
						toggleColumns(id);
					} catch {
						dialogue.open('noLastPriceFound', {
							data: client.label,
							onSubmit: () => {
								reset();
								dialogue.close();
							},
							onCancel: () => {
								reset();
								dialogue.close();
							},
						});
					}
				},
			});
		} else {
			toggleColumns(id);
		}
	};

	const toggleDropdown = useStopPropagationCallback(dropdown.toggle);
	const dropdownWrapperRef = useClickOutside<HTMLDivElement>(dropdown.close);

	return (
		<div className={clsx(className)} style={{ justifyContent: justify }}>
			<button type="button" onClick={toggleDropdown} ref={setReferenceElement} className={clsx(styles.selectTrigger)} disabled={disableAll}>
				Ціни
			</button>

			{children}

			{dropdown.isOn &&
				createPortal(
					<div ref={dropdownWrapperRef}>
						<ul ref={setPopperElement} style={popperStyles.popper} {...attributes.popper} className={styles.selectList}>
							{types.map((item) => {
								const isActive = activeIds.includes(item.id);

								return (
									<li key={item.id}>
										<PriceModifier
											isActive={isActive}
											modifierDisplayValue={item.modifierDisplayValue}
											activeColor={item.activeColor}
											bgColor={item.bgColor}
											borderColor={item.borderColor}
											fontColor={item.fontColor}
											dotColor={item.dotColor}
											hoverBgColor={item.hoverBgColor}
											onClick={(e) => {
												e.stopPropagation();
												handleSelectionSafe(item.id, item.isLongRequest);
											}}
											disabled={disableAll}
										/>
									</li>
								);
							})}
						</ul>
					</div>,
					document.querySelector('#portal'),
				)}

			{isLoading && <Spinner />}
		</div>
	);
};

export default PriceTypesSelect;

function hydrateState(key: string) {
	return function hydrate(): string[] {
		const visibilityModel = localStorageService.load<VisibilityModel>(key);

		if (visibilityModel) {
			const internalStateModel = Object.entries(visibilityModel)
				.filter(([, isVisible]) => isVisible)
				.map(([id]) => id)
				.filter(isString);

			return internalStateModel;
		}

		return [];
	};
}
