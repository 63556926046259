import clsx from 'clsx';
import Chip from 'components/Chip';
import { useBoolean } from 'hooks/useBoolean';
import React from 'react';
import { ReactComponent as Arrow } from 'static/images/chevron-down.svg';

import styles from './style.module.css';
import { IProps } from './types';

const SingleFilterWrapper: React.FC<IProps> = ({
	title,
	children,
	icon,
	expanded: isInitialExpanded = false,
	activeFiltersCount,
	indeterminateFiltersCount,
}) => {
	const expanded = useBoolean(isInitialExpanded);

	const hasActiveFilters = activeFiltersCount > 0;
	const hasIndeterminateFiltersCount = indeterminateFiltersCount > 0;

	return (
		<div className={clsx(styles.filterWrapper, { [styles.expanded]: expanded.isOn })}>
			<div className={styles.rowItem} onClick={expanded.toggle}>
				<div className={styles.titleWrapper}>
					{icon}
					{title}

					{hasActiveFilters && (
						<Chip
							hideDot
							title={String(activeFiltersCount)}
							color="var(--success-700)"
							bgColor="var(--success-50)"
							className={styles.activeFiltersCountBadge}
						/>
					)}
					{hasIndeterminateFiltersCount && (
						<Chip
							hideDot
							title={String(indeterminateFiltersCount)}
							color="var(--gray-700)"
							bgColor="var(--gray-100)"
							className={styles.activeFiltersCountBadge}
						/>
					)}
				</div>

				<Arrow style={{ ...(expanded.isOn && { transform: 'rotate(180deg)' }) }} />
			</div>

			{expanded.isOn && <div className={clsx(styles.dropdown, { [styles.expanded]: expanded.isOn })}>{children}</div>}
		</div>
	);
};

export default SingleFilterWrapper;
