import { biometricPassportIdRegex, regularPassportIdRegex } from 'validation/regex';

export const transformToMaxLength = (max: number) => (value: string) => {
	if (value?.length <= max) return value ?? '';

	return value?.slice(0, max) ?? '';
};

/**
 *
 * details see here: https://1cinfo.com.ua/Articles/Proverka_koda_po_EDRPOU.aspx
 */

export const checkEdrpou = (code: string): boolean => {
	if (!/^\d{8}$/.test(code)) return false;

	const digits = code.split('').map(Number);

	const weightsLow = [1, 2, 3, 4, 5, 6, 7];
	const weightsHigh = [7, 1, 2, 3, 4, 5, 6];
	const weightsLowAlt = [3, 4, 5, 6, 7, 8, 9];
	const weightsHighAlt = [9, 3, 4, 5, 6, 7, 8];

	const calculateControlDigit = (weights: number[]): number => {
		const sum = digits.slice(0, 7).reduce((acc, digit, idx) => acc + digit * weights[idx], 0);
		return sum % 11;
	};

	const isInRange = parseInt(code) >= 30000000 && parseInt(code) <= 60000000;
	let controlDigit = calculateControlDigit(isInRange ? weightsHigh : weightsLow);

	if (controlDigit === 10) {
		controlDigit = calculateControlDigit(isInRange ? weightsHighAlt : weightsLowAlt);
	}

	return controlDigit === digits[7];
};

export const validateRegularPassportID = (passportID: string): boolean => {
	return regularPassportIdRegex.test(passportID);
};

export const validateBiometricPassportID = (idNumber: string): boolean => {
	return biometricPassportIdRegex.test(idNumber);
};
