import { OptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import { z } from 'zod';

import { OrderStatusSchema, PaymentStatus } from './order';

export const BySumMaxOrderFilterSchema = z.string();
export const ByPaymentStatusOrderFilterSchema = z.array(PaymentStatus);
export const ByStatusOrderFilterSchema = OrderStatusSchema;
export const ByContractOrderFilterSchema = z.string();

export const ByResponsibleEmployeeOrderFilterSchema = standardizeOption<typeof OptionSchema, { '1c_uuid': string; name: string; id: number }>(
	OptionSchema,
	(manager) => ({
		label: manager.name,
		value: String(manager.id ?? ''),
	}),
);
export const ByStockOrderFilterSchema = standardizeOption<typeof OptionSchema, { id: string; title: string }>(OptionSchema, (stock) => ({
	label: stock.title,
	value: stock.id,
}));
export const ByClientOrderFilterSchema = standardizeOption<typeof OptionSchema, { id: string; name: string }>(OptionSchema, (client) => ({
	label: client.name,
	value: client.id,
}));

export const OrderFiltersSchema = z.object({
	sumMax: BySumMaxOrderFilterSchema,
	paymentStatuses: ByPaymentStatusOrderFilterSchema,
	stocks: ByStockOrderFilterSchema.array(),
	clients: ByClientOrderFilterSchema.array(),
	contracts: z.array(ByContractOrderFilterSchema),
	statuses: ByStatusOrderFilterSchema.array(),
	responsible: ByResponsibleEmployeeOrderFilterSchema.array(),
});

export type OrderFilters = z.infer<typeof OrderFiltersSchema>;
