import { ROUTES_URLS } from 'const';
import { useAppSelector } from 'hooks/redux';
import { StoreToken } from 'models/auth';
import React from 'react';
import { Navigate, Outlet, useLoaderData } from 'react-router-dom';
import { authSelectors } from 'store/reducers/auth/selectors';

const RestrictedRoute = () => {
	const { token } = useLoaderData() as { token: StoreToken };
	const me = useAppSelector(authSelectors.selectWhoamI);

	const renderOutlet = !token && !me;

	return renderOutlet ? <Outlet /> : <Navigate replace to={ROUTES_URLS.HOME} />;
};

export default RestrictedRoute;
