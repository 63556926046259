import clsx from 'clsx';
import React from 'react';
import { ReactComponent as MinusIcon } from 'static/images/minus.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus-circle.svg';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';
import { isString } from 'utils/type-guards';

import styles from './styles.module.css';
import type { CounterProps } from './types';

const Counter: React.FC<CounterProps> = ({
	onQuantityChange,
	onBlur,
	disabled = false,
	className,
	value: counter,
	withDeleteButton = false,
	onDelete,
	onKeyDown,
	min = 0,
}) => {
	const isDecrementDisabled = Number(counter) <= min;

	const increment = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (isString(counter) && counter.includes('.')) {
			const [int, decimal] = counter.split('.');
			return onQuantityChange?.(`${Number(int) + 1}.${decimal}`);
		}

		onQuantityChange?.(Number(counter) + 1);
	};

	const decrement = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (isDecrementDisabled) return;

		if (isString(counter) && counter.includes('.')) {
			const [int, decimal] = counter.split('.');
			return onQuantityChange?.(`${Number(int) - 1}.${decimal}`);
		}

		onQuantityChange?.(Number(counter) - 1);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value.replace(/^0+(?=\d)/, '');

		e.target.value = inputValue;

		if (min === 0) {
			return onQuantityChange?.(inputValue);
		}

		if (Number(inputValue) < min) {
			return onQuantityChange(min);
		}
	};

	const isDecrementButtonDisabled = disabled || isDecrementDisabled;
	const isIncrementButtonDisabled = disabled;

	return (
		<div data-counter className={clsx(styles.counter, className)}>
			{withDeleteButton && (
				<button className={styles.counterButton} type="button" onClick={onDelete}>
					<TrashIcon />
				</button>
			)}
			<button className={styles.counterButton} type="button" onClick={decrement} disabled={isDecrementButtonDisabled}>
				<MinusIcon />
			</button>

			<input type="text" onKeyDown={onKeyDown} onBlur={onBlur} className={styles.counterAmount} value={counter} onChange={onChange} />

			<button disabled={isIncrementButtonDisabled} className={styles.counterButton} type="button" onClick={increment}>
				<PlusIcon />
			</button>
		</div>
	);
};

export default Counter;
