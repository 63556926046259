import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { hashString } from 'utils/shared';
import { isString } from 'utils/type-guards';

import type { KeyHashStrategy, RegisterKeyOptions, SearchParamsControllerContext, SearchParamsControllerProps } from './types';

const Context = createContext<SearchParamsControllerContext>(null);

export const useSearchParamsController = () => {
	const ctx = useContext(Context);

	if (!ctx) throw new Error('useSearchParamsController must be use inside <SearchParamsController />');

	return ctx;
};

const ignoreUrlSearchKeysFallback = [];
const tupleKeyDelimiter = '@';
const multipleDelimiter = '[]';

const encodeTupleKey = (...segments: string[]) => segments.join(tupleKeyDelimiter);
const encodeTupleValue = (...segments: string[]) => segments.join(tupleKeyDelimiter);
const decodeTupleKey = (compoundKey: string) => compoundKey.split(tupleKeyDelimiter);
const decodeTupleValue = (compoundKey: string) => compoundKey.split(tupleKeyDelimiter);
const isTupleKey = (candidateKey: string) => !!candidateKey?.includes(tupleKeyDelimiter);
const encodeMultipleKey = (...segments: string[]) => segments.join(tupleKeyDelimiter);
const decodeMultipleKey = (compoundKey: string) => compoundKey.replaceAll(tupleKeyDelimiter, '');
const isMultipleKey = (candidateKey: string) => !!candidateKey?.includes(multipleDelimiter);

const isSingletonHashStrategy = (strategy: unknown): strategy is keyof KeyHashStrategy => {
	return isString(strategy) && strategy === 'singleton';
};
const isMultipleHashStrategy = (strategy: unknown): strategy is keyof KeyHashStrategy => {
	return isString(strategy) && strategy === 'multiple';
};
const isTupleHashStrategy = (strategy: unknown): strategy is keyof KeyHashStrategy => {
	return isString(strategy) && strategy === 'tuple';
};

const SearchParamsController = ({ children, ignoreUrlSearchKeys = ignoreUrlSearchKeysFallback }: SearchParamsControllerProps) => {
	const hashQueryKeysMap = useRef<Map<string, string>>(new Map());
	const templateKeysMap = useRef<Map<string, string>>(new Map());
	const queryHashKeysMap = useRef<Map<string, string>>(new Map());
	const hashQueryTable = hashQueryKeysMap.current;
	const queryHashTable = queryHashKeysMap.current;
	const templateTable = templateKeysMap.current;

	const [searchParams, setSearchParams] = useSearchParams();
	const store = useForm<Record<string, AnyArg>>();

	const getCountAllAppliedQueryKeys = useCallback(() => {
		const newSearchParams = new URLSearchParams(searchParams);

		ignoreUrlSearchKeys.forEach((key) => {
			newSearchParams.delete(key);
		});

		return newSearchParams.size;
	}, [searchParams, ignoreUrlSearchKeys]);

	const getCountSingleAppliedQueryKey = useCallback(
		(hash: string | null) => {
			if (!hash) return 0;

			const key = hashQueryTable.get(hash);
			const value = searchParams.getAll(key);
			const isTuple = isTupleKey(key ?? '');

			if (isTuple) {
				const [queryStartKey, queryEndKey] = decodeTupleKey(key);
				const hasQueryStartKey = searchParams.has(queryStartKey);
				const hasQueryEndKey = searchParams.has(queryEndKey);

				if (!hasQueryStartKey || !hasQueryEndKey) {
					return 0;
				}

				return 1;
			}

			if (Array.isArray(value)) {
				return value.length;
			}

			return 0;
		},
		[searchParams, store],
	);

	const apply = useCallback(
		(e?: React.FormEvent<HTMLFormElement>) =>
			store.handleSubmit((values: Record<string, AnyArg>) => {
				const storeData = Object.entries(values ?? {});
				const newSearchParams = new URLSearchParams();
				const templateId = searchParams.get('template');

				storeData.forEach(([keyHash, data]) => {
					const queryKey = hashQueryTable.get(keyHash);

					if (isTupleKey(queryKey)) {
						const [startKey, endKey] = decodeTupleKey(queryKey);
						const isValidTuple = Array.isArray(data) && data.length === 2;

						if (!isValidTuple) {
							newSearchParams.delete(startKey);
							newSearchParams.delete(endKey);
							return;
						}
						const [startValue, endValue] = data;

						newSearchParams.set(startKey, startValue);
						newSearchParams.set(endKey, endValue);
						return;
					}

					if (isMultipleKey(queryKey)) {
						const decodedQueryKey = decodeMultipleKey(queryKey);
						const hasValue = Array.isArray(data) && data.length > 0;

						if (!hasValue) {
							newSearchParams.delete(decodedQueryKey);
							return;
						}

						data.forEach((val) => {
							newSearchParams.append(decodedQueryKey, val);
						});

						return;
					}

					if (isString(data)) {
						if (!data) {
							return newSearchParams.delete(queryKey);
						}

						return newSearchParams.set(queryKey, data);
					}
				});

				const template = templateTable.get(templateId);
				const paramsToBeApplied = newSearchParams.toString();
				const areEqual = template === paramsToBeApplied;

				if (templateId && areEqual) {
					newSearchParams.set('template', templateId);
				}

				setSearchParams(newSearchParams);
			})(e),
		[searchParams],
	);

	const clearAll = () => {
		const newStoreData = {};

		Object.entries(store.getValues() ?? {}).forEach(([keyHash, value]) => {
			if (Array.isArray(value)) {
				newStoreData[keyHash] = [];
				return;
			}

			if (isString(value)) {
				newStoreData[keyHash] = '';
				return;
			}
		});

		templateTable.clear();
		store.reset(newStoreData);
		setSearchParams(new URLSearchParams());
	};

	const context = useMemo(() => {
		return {
			apply,
			clearAll,
			getCountAllAppliedQueryKeys,
			getCountSingleAppliedQueryKey,
			registerKey: (key: string | string[], defaultValue: AnyArg, options?: RegisterKeyOptions) => {
				const { hashStrategy } = options ?? {};

				if (isTupleHashStrategy(hashStrategy) && Array.isArray(key)) {
					const [startQueryKey, endQueryKey] = key;
					const compoundKey = encodeTupleKey(startQueryKey, endQueryKey);
					const keyHash = hashString(compoundKey);
					queryHashTable.set(compoundKey, keyHash);
					hashQueryTable.set(keyHash, compoundKey);

					store.setValue(keyHash, defaultValue);
					return keyHash;
				}

				if (isMultipleHashStrategy(hashStrategy) && isString(key)) {
					const compoundKey = encodeMultipleKey(key);
					const keyHash = hashString(compoundKey);
					queryHashTable.set(compoundKey, keyHash);
					hashQueryTable.set(keyHash, compoundKey);

					store.setValue(keyHash, defaultValue);
					return keyHash;
				}

				if (isSingletonHashStrategy(hashStrategy) && isString(key)) {
					const keyHash = hashString(key);
					queryHashTable.set(key, keyHash);
					hashQueryTable.set(keyHash, key);
					store.setValue(keyHash, defaultValue);
					return keyHash;
				}

				return '';
			},
			generateQueryTemplateString: () => {
				const newSearchParams = new URLSearchParams();

				Object.entries(store.getValues() ?? {}).forEach(([keyHash, value]) => {
					const queryKey = hashQueryTable.get(keyHash);

					if (isTupleKey(queryKey)) {
						const [startValue, endValue] = value;

						if (startValue !== undefined && endValue !== undefined) {
							newSearchParams.set(queryKey, encodeTupleValue(startValue, endValue));
						}

						return;
					}

					if (isMultipleKey(queryKey)) {
						const decodedQueryKey = decodeMultipleKey(queryKey);
						if (Array.isArray(value)) {
							return value.forEach((val) => {
								newSearchParams.append(decodedQueryKey, val);
							});
						}
					}

					if (isString(value)) {
						if (!value) return;

						return newSearchParams.set(queryKey, value);
					}
				});

				return newSearchParams.toString();
			},
			applyTemplate: (paramsString: string) => {
				const templateSearchParams = new URLSearchParams(paramsString);
				const newSearchParams = new URLSearchParams();
				const templateId = templateSearchParams.get('template');

				templateSearchParams.delete('template');

				const newStoreData = {};

				Object.entries(store.getValues() ?? {}).forEach(([keyHash, value]) => {
					if (Array.isArray(value)) {
						newStoreData[keyHash] = [];
						return store.setValue(keyHash, []);
					}

					if (isString(value)) {
						newStoreData[keyHash] = '';
						return store.setValue(keyHash, '');
					}
				});

				Array.from(templateSearchParams.entries()).forEach(([queryKey, value]) => {
					if (isTupleKey(queryKey)) {
						const [queryStartKey, queryEndKey] = decodeTupleKey(queryKey);
						const [queryStartValue, queryEndValue] = decodeTupleValue(value);

						if (queryStartValue === undefined || queryEndValue === undefined) {
							return;
						}

						const compoundHashKey = hashString(encodeTupleKey(queryStartKey, queryEndKey));

						newSearchParams.set(queryStartKey, queryStartValue);
						newSearchParams.set(queryEndKey, queryEndValue);
						newStoreData[compoundHashKey] = [queryStartValue, queryEndValue];
						return;
					}

					if (isMultipleKey(queryKey)) {
						const decodedQueryKey = decodeMultipleKey(queryKey);
						const hashKey = hashString(queryKey);
						if (newStoreData[hashKey]?.length > 0) return;

						const values = templateSearchParams.getAll(decodedQueryKey);
						newStoreData[hashKey] = values;

						values.forEach((val) => {
							newSearchParams.append(decodedQueryKey, val);
						});

						return;
					}

					const hashKey = hashString(queryKey);
					newSearchParams.set(queryKey, value);
					newStoreData[hashKey] = value;
				});

				templateTable.clear();
				templateTable.set(templateId, newSearchParams.toString());

				newSearchParams.set('template', templateId);
				store.reset(newStoreData);
				setSearchParams(newSearchParams);
			},
			getCountSingleIndeterminateQueryKey: (keyHash: string) => {
				if (!keyHash) return 0;

				const queryKey = hashQueryTable.get(keyHash);
				const storedValue = store.getValues(keyHash);

				if (isTupleKey(queryKey)) {
					const [queryStartKey, queryEndKey] = decodeTupleKey(queryKey);
					const [startValue, endValue] = storedValue;

					if (startValue === undefined || endValue === undefined) {
						return 0;
					}

					if (startValue !== undefined && searchParams.has(queryStartKey) && endValue !== undefined && searchParams.has(queryEndKey)) {
						return 0;
					}

					return 1;
				}

				if (isMultipleKey(queryKey)) {
					const allAppliedQueryFilters = [...searchParams.getAll(queryKey)];
					const delta = storedValue.length - allAppliedQueryFilters.length;

					return Math.max(0, delta);
				}

				return storedValue && !searchParams.has(queryKey) ? 1 : 0;
			},
			resetKey: (keyHash: string) => {
				const queryKey = hashQueryTable.get(keyHash);

				if (isTupleKey(queryKey) || isMultipleKey(queryKey)) {
					return store.setValue(keyHash, []);
				}

				store.setValue(keyHash, '');
			},
			getValue: store.getValues,
			setValue: store.setValue,
			isDirty: store.formState.isDirty,
		};
	}, [store.formState.isDirty, store.getValues, store.setValue, getCountAllAppliedQueryKeys, getCountSingleAppliedQueryKey, clearAll, apply]);

	return (
		<FormProvider {...store}>
			<Context.Provider value={context}>{children}</Context.Provider>
		</FormProvider>
	);
};

export default SearchParamsController;
