import { createContext, useContext } from 'react';
export interface MainSearchContext {
	onChange: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
	query: string;
}

const Context = createContext<MainSearchContext>(null);

export const useMainSearch = () => {
	const ctx = useContext<MainSearchContext>(Context);

	if (!ctx) throw new Error('Must be inside <MainSearch />');

	return ctx;
};

export const MainSearchProvider = Context.Provider;
