/* eslint-disable react/prop-types */
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useSearchParamsController } from '..';
import type { SingleStringController as SingleStringControllerType } from '../types';

export type SingleStringControllerProps = SingleStringControllerType & {
	render: (props: {
		value: string;
		onChange: (value: string) => void;
		hasActiveFilter?: boolean;
		activeFiltersCount?: number;
		indeterminateFiltersCount?: number;
		keyHash: string;
		queryKey: string;
	}) => React.ReactNode;
};

const SingleStringController: React.FC<SingleStringControllerProps> = ({ queryKey, render }) => {
	const [key, setKey] = useState(null);

	const [searchParams] = useSearchParams();
	const store = useSearchParamsController();

	const activeFiltersCount = store.getCountSingleAppliedQueryKey(key);

	useLayoutEffect(() => {
		const urlValue = searchParams.get(queryKey);
		const keyHash = store.registerKey(queryKey, urlValue ?? '', { hashStrategy: 'singleton' });
		setKey(keyHash);
	}, []);

	const renderer = useCallback(
		({ field }: { field: ControllerRenderProps }) => {
			const indeterminateFiltersCount = store.getCountSingleIndeterminateQueryKey(key);
			const el = render({
				value: field.value,
				onChange: field.onChange,
				activeFiltersCount,
				hasActiveFilter: activeFiltersCount > 0,
				indeterminateFiltersCount,
				keyHash: key,
				queryKey,
			});

			return <>{el}</>;
		},
		[render, activeFiltersCount, queryKey, key],
	);

	if (!key) return null;

	return <Controller name={key} render={renderer} />;
};

SingleStringController.displayName = 'string-single';

export default SingleStringController;
