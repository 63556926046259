import clsx from 'clsx';
import Avatar from 'components/Avatar';
import React from 'react';

import styles from './styles.module.css';
import type { AvatarWithOnlineStatusProps } from './types';

const AvatarWithOnlineStatus: React.FC<AvatarWithOnlineStatusProps> = ({ isOnline, indicatorSize, ...restAvatarProps }) => {
	return (
		<Avatar {...restAvatarProps}>
			<span
				className={clsx(styles.statusIndicator, { [styles.online]: isOnline })}
				style={{ '--status-indicator-size': indicatorSize } as React.CSSProperties}
			/>
		</Avatar>
	);
};

export default AvatarWithOnlineStatus;
