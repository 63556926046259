import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import FastGroupFilters from 'components/ClientsFilter/FastGroupFilters';
import ClientsTopBar from 'components/ClientsTopBar';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import FilterWrapper from 'components/FilterWrapper';
import SearchParamsController from 'components/SearchParamsController';
import Select from 'components/Select';
import SearchParamsControllerAdapter from 'components/SharedFilterDrawer/adapters/SearchParamsControllerAdapter';
import ByClientFilter from 'components/SharedFilterDrawer/filters/ByClientFilter';
import ByResponsibleFilter from 'components/SharedFilterDrawer/filters/ByResponsibleFilter';
import BySegmentFilter from 'components/SharedFilterDrawer/filters/BySegmentFilter';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import SegmentDropDownFilter from 'components/Table/Filters/SegmentDropDownFilter';
import SettingButton from 'components/Table/Filters/SettingsButton';
import Pagination from 'components/Table/Pagination';
import EmptyTable from 'components/Table/TableComponents/EmptyTable';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { breakPoints, CLIENTS_FAST_FILTERS, ROUTES_URLS } from 'const';
import { useAdjustableColumns } from 'hooks/useAdustableColumns';
import { useBoolean } from 'hooks/useBoolean';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import { ClientPreview } from 'models/client';
import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetClientsFiltersDataQuery, useGetClientsQuery } from 'store/reducers/clients/clientsSliceApi';
import { prepareUrl } from 'utils/shared';

import { useClientsColumns } from './columns';
import { clientsIcons } from './icons';
import styles from './style.module.css';
import type { IProps } from './types';

const FilterModal = lazy(() => import('components/FilterModal'));
const ClientsMobileTable = lazy(() => import('components/Table/MobileViews/ClientsMobileTable'));
const SharedFilterDrawer = lazy(() => import('components/SharedFilterDrawer'));

const clientPerPageOptions = ['12', '24', '48'];

const Clients: React.FC<IProps> = ({ breadcrumbs, onRowClick, orderPageView, selectable = true }) => {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const { data: { segments = [], managers = [] } = {} } = useGetClientsFiltersDataQuery(undefined);
	const { data: { data: clientsList, page, pagesCount, total } = {}, isFetching, isLoading } = useGetClientsQuery(searchParams.toString());

	const filterDropdown = useBoolean();
	const filterDrawer = useBoolean();
	const visibilityInterface = useBoolean();
	const filterDropdownToggle = useStopPropagationCallback(filterDropdown.toggle);
	const filterDrawerToggle = useStopPropagationCallback(filterDrawer.toggle);
	const visibilityInterfaceToggle = useStopPropagationCallback(visibilityInterface.toggle);

	const crumbs: Breadcrumb[] = breadcrumbs ?? [{ label: 'Клієнти', href: prepareUrl(ROUTES_URLS.CLIENTS) }];
	const perPage = searchParams.get('perPage') ?? clientPerPageOptions[0];
	const hasClients = clientsList?.length > 0;

	const handleClientClick = (client: ClientPreview) => {
		if (onRowClick) {
			return onRowClick(client);
		}

		const url = prepareUrl(ROUTES_URLS.CLIENT_PAGE, { id: client.id });
		navigate(url);
	};

	const handleNavigateToCreateClient = () => {
		const url = prepareUrl(ROUTES_URLS.CLIENT_CREATE);

		navigate(url);
	};

	const handleRowClick = (row: unknown) => {
		onRowClick?.(row);
	};

	const columns = useClientsColumns(handleRowClick);
	const { visibilityModel, setVisibilityModel } = useAdjustableColumns(columns, { saveConfigKey: '/clients' });
	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: '/clients' });

	const onPerPageChange = (newPageSize: string) => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('perPage', newPageSize);

		setSearchParams(newSearchParams);
	};

	useEffect(() => {
		const newSearchParams = new URLSearchParams(searchParams);

		const perPageParam = newSearchParams.get('perPage');
		const isValidPerPage = perPageParam === null || clientPerPageOptions.includes(perPageParam);

		if (isValidPerPage) return;

		newSearchParams.set('perPage', clientPerPageOptions[0]);
		setSearchParams(newSearchParams);
	}, []);

	const RenderMobileTableRow = useCallback(({ row }) => <ClientsMobileTable columnId="name" row={row} onSelect={handleRowClick} />, []);
	const RenderMobileTableHeader = useCallback(
		({ headerGroups }) => <MobileHeader justify="space-between" px="10px" headerGroups={headerGroups} renderHeaders={['chooseClient', 'sort']} />,
		[],
	);

	if (isLoading) {
		return <PageContentSkeleton />;
	}

	return (
		<div className="main-wrapper">
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} onClickFilter={filterDropdownToggle} />

				<MediaQuery minWidth={breakPoints.MOBILE}>
					<ClientsTopBar onCreateClientButtonClick={handleNavigateToCreateClient} onFiltersButtonClick={filterDrawerToggle} />

					<Spacer height="31px" />

					<FilterWrapper className={styles.filtersWrapper}>
						<div className={styles.filtersGroup}>
							<SegmentDropDownFilter multiple listOfFilters={segments} className={styles.segments} />
							<FastGroupFilters queryKey="segment[]" listOfFilters={CLIENTS_FAST_FILTERS} className={styles.fastFilters} />

							<MediaQuery minWidth={breakPoints.MOBILE}>
								<Select valuesList={clientPerPageOptions} value={perPage} setValue={onPerPageChange} label="Показувати по:" />
							</MediaQuery>

							<SettingButton onClick={visibilityInterfaceToggle}>
								<ColumnsSettingsMenu
									open={visibilityInterface.isOn}
									onClose={visibilityInterfaceToggle}
									columns={columns}
									visibilityModel={visibilityModel}
									setColumns={setVisibilityModel}
								/>
							</SettingButton>
						</div>
					</FilterWrapper>
				</MediaQuery>

				{!hasClients && <EmptyTable text="Жодного клієнта не знайдено" />}

				<Suspense>
					{hasClients && (
						<div className={styles.tableContainer}>
							<Table
								{...(!!orderPageView && { pageType: 'order' })}
								allData={clientsList}
								selectable={selectable}
								visibilityModel={visibilityModel}
								pinningModel={pinningModel}
								onPinningModelChange={setPinningModel}
								columns={columns}
								onClickRow={handleClientClick}
								mobileViewComponent={RenderMobileTableRow}
								mobileHeader={RenderMobileTableHeader}
							/>

							{pagesCount > 1 && <Pagination pagesCount={pagesCount} page={page} />}
						</div>
					)}
				</Suspense>

				<Suspense fallback={<Spinner />}>
					{filterDropdown.isOn && <FilterModal open={filterDropdown.isOn} onClose={filterDropdownToggle} />}
				</Suspense>

				<SearchParamsController>
					{filterDrawer.isOn && (
						<SearchParamsControllerAdapter
							render={(adapterProps) => {
								return (
									<SharedFilterDrawer
										searchResultsCount={total}
										openDrawer={filterDrawer.isOn}
										onClose={filterDrawerToggle}
										type={'clients'}
										{...adapterProps}
									>
										<ByClientFilter queryKey="client[]" title="Контрагент" />
										<ByResponsibleFilter queryKey="manager[]" title="Менеджер" options={managers} />
										<BySegmentFilter queryKey="segment[]" options={segments} />
									</SharedFilterDrawer>
								);
							}}
						/>
					)}
				</SearchParamsController>
			</div>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<div className="safe-area-bottom" />
				<div className={styles.bottomPanel}>
					<Button variant="default" text="Фільтрувати" icon={clientsIcons.Funnel} className={styles.filterBtn} />
					<Button
						variant="default"
						text="Створити клієнта"
						icon={clientsIcons.Plus}
						className={styles.addClientBtn}
						onClick={handleNavigateToCreateClient}
					/>
				</div>
			</MediaQuery>

			{isFetching && <Spinner />}
		</div>
	);
};

export default Clients;
