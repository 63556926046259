/* eslint-disable no-console */
import clsx from 'clsx';
import Button from 'components/Button';
import ClickAwayListener from 'components/ClickAwayListener';
import CustomRadioButton from 'components/CustomRadioButton';
import IconButton from 'components/IconButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useLoadableOptions } from 'hooks/useLoadableOptions';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Magnify from 'static/images/Magnify.svg';
import Micro from 'static/images/micro.svg';
import { setSearchBy } from 'store/reducers/search';
import { selectSearchBy } from 'store/reducers/search/selectors';

import { getSearchConfig } from './search.config';
import SearchDropDown from './SearchDropDown';
import styles from './style.module.css';
import type { IProps } from './types';

const MainSearch: React.FC<IProps> = ({
	tableMode,
	asRegularInput,
	highlightMatch = true,
	searchOnClick = false,
	loadAsyncData,
	renderItem,
	onItemClick,
	type = 'text',
	placeholder,
	...restProps
}) => {
	const dispatch = useAppDispatch();
	const searchBy = useAppSelector(selectSearchBy);
	const inputRef = useRef<HTMLInputElement>(null);

	const [searchParams, setSearchParams] = useSearchParams();
	const [input, setInput] = useState('');
	const [search, setSearch] = useState('');
	const [isDropdownOpened, setIsDropdownOpened] = useState(false);

	const { buttonText } = getSearchConfig();

	const skip = !isDropdownOpened;
	const asyncLoader = useCallback(
		async (signal: AbortSignal) => {
			const response = await loadAsyncData(search, signal);

			if ('data' in response) {
				return response.data;
			}

			return [];
		},
		[loadAsyncData],
	);

	const { options, isLoading, isError, refetch, cancel, reset } = useLoadableOptions({ skip, asyncLoader });

	const activeUrlQuery = 'activeUrlQuery' in restProps ? restProps.activeUrlQuery : 'query';
	const defaultValue = searchParams.get(activeUrlQuery) ?? '';
	const option = searchBy === 'product' ? 0 : 1;

	const updateSearchByOption = (newSearchBy: Parameters<typeof setSearchBy>[number]['searchBy']) => (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (searchBy === newSearchBy) {
			return;
		}

		setIsDropdownOpened(false);
		reset();
		dispatch(setSearchBy({ searchBy: newSearchBy }));
	};

	useEffect(() => {
		if (asRegularInput) {
			dispatch(setSearchBy({ searchBy: 'none' }));
		}
	}, [asRegularInput]);

	useEffect(() => {
		if (asRegularInput && !defaultValue && inputRef.current) {
			inputRef.current.value = '';
		}
	}, [defaultValue, asRegularInput]);

	useEffect(() => {
		if (defaultValue && inputRef.current) {
			inputRef.current.value = defaultValue;
		}
	}, []);

	const onChangedDebounce = useDebouncedCallback((newValue: string) => {
		setSearch(newValue.toString());
	}, 500);

	const updateSearchParams = (value: string) => {
		const newSearchParams = new URLSearchParams(searchParams);

		if (value.trim()) {
			newSearchParams.set(activeUrlQuery, value);
		} else {
			newSearchParams.delete(activeUrlQuery);
		}

		setSearchParams(newSearchParams);
	};

	const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		if (asRegularInput && !searchOnClick) {
			updateSearchParams(value);
		} else {
			setInput(value);
			onChangedDebounce(value);
		}
	};

	const expandDropdown = () => {
		if (asRegularInput) return;

		setIsDropdownOpened(true);
	};
	const collapseDropdown = () => {
		if (asRegularInput) return;

		cancel?.();
		setIsDropdownOpened(false);
	};

	const showDropdown = !asRegularInput && isDropdownOpened;
	const isDisabled = isLoading;
	const isSearchBySet = searchBy !== 'none';
	const searchByPlaceholder = searchBy === 'client' ? 'Почніть писати імʼя клієнта' : 'Почніть писати назву товару';

	let placeholderText = 'Почніть писати назву товару';

	if (placeholder) {
		placeholderText = placeholder;
	} else if (isSearchBySet) {
		placeholderText = searchByPlaceholder;
	} else if (tableMode) {
		placeholderText = 'Почніть писати';
	}

	let tableModeButtonElement = (
		<div className={styles.btnsWrapper}>
			<Button disabled={isDisabled} text={buttonText} onClick={() => updateSearchParams(input)} variant="smallRounded" />
		</div>
	);

	if ('querySwitchButtonsList' in restProps) {
		const { querySwitchButtonsList } = restProps;

		tableModeButtonElement = (
			<div className={styles.btnsWrapper}>
				{querySwitchButtonsList.map(({ title, isActive, onClick }, idx) => {
					const handleClick = () => {
						onClick();

						if (inputRef.current) {
							inputRef.current.value = '';
						}

						updateSearchParams(input);
					};

					return (
						<Button
							key={title + idx}
							disabled={isDisabled}
							text={title}
							onClick={handleClick}
							variant="smallRounded"
							className={clsx(styles.switchUrlQueryButton, { [styles.inActive]: !isActive })}
						/>
					);
				})}
			</div>
		);
	}

	return (
		<div className={styles.searchWrapper}>
			<ClickAwayListener onClickAway={collapseDropdown}>
				<div className={styles.mainSearch}>
					<div className={styles.iconsWrapper}>
						<IconButton icon={Micro} width={'40px'} height={'40px'} background={'#EFF8FF'} onClick={() => console.log('111')} />
						<img src={Magnify} />
					</div>

					<input
						{...(asRegularInput && { defaultValue })}
						{...(!asRegularInput && { value: input })}
						ref={inputRef}
						type={type}
						id="main-search"
						className={styles.mainSearchInput}
						placeholder={placeholderText}
						onChange={handleSearchValueChange}
						onFocus={expandDropdown}
						onClick={(e) => e.stopPropagation()}
					/>
					{tableMode ? (
						tableModeButtonElement
					) : (
						<div className={styles.btnsWrapper}>
							<CustomRadioButton
								disabled={isDisabled}
								checked={searchBy === 'product'}
								text={'Товар'}
								onClick={updateSearchByOption('product')}
							/>
							<CustomRadioButton
								disabled={isDisabled}
								checked={searchBy === 'client'}
								text={'Клієнт'}
								onClick={updateSearchByOption('client')}
							/>
						</div>
					)}
				</div>

				{showDropdown && (
					<SearchDropDown
						highlightMatch={highlightMatch}
						query={search}
						renderItem={renderItem}
						options={options}
						option={option}
						onItemClick={onItemClick}
						isLoading={isLoading}
						isError={isError}
						refetch={refetch}
					/>
				)}
			</ClickAwayListener>
		</div>
	);
};

export default MainSearch;
