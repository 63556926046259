import clsx from 'clsx';
import { CLIENT_STATE_NAMES_LIST, EDRPOU_CODE_LENGTH } from 'const';
import type { IndividualClientFormFields, LegalClientFormFields } from 'models/IClientFormFields';
import React from 'react';
import EmailIcon from 'static/images/email.svg';
import { uuid } from 'utils/shared';
import { transformToMaxLength } from 'validation/functions';

import { RichTextLabel } from '../ClientFormCreate';
import { loadActivitiesOptionList, loadContractTypesOptionList, loadPhoneAssociatedMessengersOptions } from '../optionsLoaders';
import PhoneArrayField from '../PhoneArrayField';
import type { IProps as PhoneArrayFieldProps } from '../PhoneArrayField/types';
import styles from '../styles.module.css';
import { AutocompleteField, InputWithCopyToClipboardAction, MultipleSelect, PlainSelect, RichText } from './inputs';

// *NOTE - here inside root Array
// 1. Arrays represent GROUP FIELDS
// 2. Objects represent FIELDS
export const individualClientFormFields: IndividualClientFormFields = [
	[
		{
			id: uuid(),
			name: 'name',
			type: 'text',
			label: 'ПІБ',
			className: clsx(styles.mediumBold, styles.regularFontColor, styles.dimPlaceholder),
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть ПІБ',
			Component: InputWithCopyToClipboardAction,
		},
	],
	[
		{
			name: 'phones',
			id: uuid(),
			type: 'phone',
			label: 'Номер телефону',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть номер',
			ability: ['view', 'clients.client.phones'],
			className: clsx(styles.semiBold, styles.highlightedFontColor),
			Component: ({ name, ...props }: PhoneArrayFieldProps) => (
				<PhoneArrayField
					{...props}
					name={name}
					phoneAccessorPath="number"
					phoneTypeAccessorPath="type"
					limit={3}
					loadOptions={loadPhoneAssociatedMessengersOptions}
				/>
			),
		},
		{
			name: 'email',
			id: uuid(),
			type: 'text',
			label: 'Email',
			icon: EmailIcon,
			iconPosition: 'leading',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть email',
			ability: ['view', 'clients.client.email'],
			fieldClassName: styles.email,
			className: clsx(styles.regularFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
		{
			name: 'passport',
			id: uuid(),
			type: 'text',
			label: 'Паспорт',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть серію та номер паспорта',
			className: clsx(styles.regularFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
		{
			name: 'ipnCode',
			id: uuid(),
			type: 'number',
			label: 'ІПН',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть ІПН',
			className: clsx(styles.regularFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
		{
			name: 'address',
			id: uuid(),
			label: 'Адреса',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть адресу',
			ability: ['view', 'clients.client.address'],
			className: clsx(styles.regularFontColor, styles.autocomplete, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
	],
	[
		{
			name: 'contract',
			id: uuid(),
			label: 'Договір',
			className: clsx(styles.regularFontColor, styles.multipleSelect, styles.select, styles.dimPlaceholder),
			placeholder: 'Не зазначено',
			createPlaceholder: 'Оберіть договір',
			Component: MultipleSelect,
			loadAsyncOptions: loadContractTypesOptionList,
		},
	],
	[
		{
			name: 'status',
			id: uuid(),
			label: 'Стан клієнта',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Виберіть статус',
			ability: ['view', 'clients.client.status'],
			className: clsx(styles.select, styles.dimPlaceholder),
			Component: PlainSelect,
			valuesList: CLIENT_STATE_NAMES_LIST,
		},
		{
			name: 'comment',
			id: uuid(),
			label: <RichTextLabel />,
			fieldClassName: clsx(styles.richTextFiled, styles.alignStart, styles.whiteSpace, styles.noShrink),
			Component: RichText,
		},
	],
];
export const legalClientFormFields: LegalClientFormFields = [
	[
		{
			name: 'organizationName',
			id: uuid(),
			type: 'text',
			label: 'Назва організації',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть назву',
			className: clsx(styles.semiBold, styles.highlightedFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
		{
			name: 'name',
			id: uuid(),
			type: 'text',
			label: 'Контактна особа',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть імʼя',
			className: clsx(styles.mediumBold, styles.regularFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
	],
	[
		{
			name: 'phones',
			id: uuid(),
			type: 'phone',
			label: 'Номер телефону',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть номер',
			ability: ['view', 'clients.client.phones'],
			className: clsx(styles.semiBold, styles.highlightedFontColor),
			Component: ({ name, ...props }: PhoneArrayFieldProps) => (
				<PhoneArrayField
					{...props}
					name={name}
					limit={3}
					phoneAccessorPath="number"
					phoneTypeAccessorPath="type"
					loadOptions={loadPhoneAssociatedMessengersOptions}
				/>
			),
		},
		{
			name: 'email',
			id: uuid(),
			type: 'text',
			label: 'Email',
			icon: EmailIcon,
			iconPosition: 'leading',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть email',
			ability: ['view', 'clients.client.email'],
			fieldClassName: styles.email,
			className: clsx(styles.regularFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
		{
			name: 'edrpouCode',
			id: uuid(),
			type: 'number',
			label: 'ЄДРПОУ',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть код ЄДРПОУ',
			className: clsx(styles.regularFontColor, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
			transform: transformToMaxLength(EDRPOU_CODE_LENGTH),
		},
		{
			name: 'address',
			id: uuid(),
			label: 'Адреса',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Введіть адресу',
			ability: ['view', 'clients.client.address'],
			className: clsx(styles.regularFontColor, styles.autocomplete, styles.dimPlaceholder),
			Component: InputWithCopyToClipboardAction,
		},
	],
	[
		{
			name: 'contract',
			id: uuid(),
			label: 'Договір',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Оберіть договір',
			className: clsx(styles.regularFontColor, styles.multipleSelect, styles.select, styles.dimPlaceholder),
			Component: MultipleSelect,
			loadAsyncOptions: loadContractTypesOptionList,
		},
	],
	[
		{
			name: 'status',
			id: uuid(),
			label: 'Стан клієнта',
			placeholder: 'Не зазначено',
			createPlaceholder: 'Виберіть статус',
			ability: ['view', 'clients.client.status'],
			Component: PlainSelect,
			valuesList: CLIENT_STATE_NAMES_LIST,
			className: clsx(styles.select, styles.dimPlaceholder),
		},
		{
			name: 'comment',
			id: uuid(),
			label: <RichTextLabel />,
			fieldClassName: clsx(styles.richTextFiled, styles.alignStart, styles.whiteSpace, styles.noShrink),
			Component: RichText,
		},
	],
];

export const specializationField = {
	name: 'activities',
	id: uuid(),
	label: 'Вид діяльності',
	forceFocus: true,
	Component: AutocompleteField,
	loadAsyncOptions: loadActivitiesOptionList,
	placeholder: 'Оберіть макс. 2 опції',
	multiple: true as const,
	tagLimit: 2,
	className: clsx(styles.specialization, styles.select, styles.dimPlaceholder),
};
