import ColorfulSelect from 'components/ColorfulSelect';
import { breakPoints } from 'const';
import { CatalogueService } from 'models/service';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MediaQuery from 'react-responsive';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { isObject } from 'utils/type-guards';

import RootButton from './RootButton';
import styles from './styles.module.css';
import { useBackdrop } from './useBackdrop';

interface ServiceOptionSelectMajorFieldProps {
	entityId: string;
	suborderIndex: number;
	placeholder?: string;
	serviceType: string;
	disabled?: boolean;
}

const getServiceListOptions = (service: CatalogueService | null, selectedId: string) => {
	if (isObject(service) && 'subCategories' in service) {
		return service.subCategories?.find((entity) => entity.id === selectedId)?.services ?? [];
	} else {
		return [];
	}
};

const getInitialValue = (value: AnyArg, service: AnyArg, serviceType: string) => {
	return {
		id: value?.id ?? '',
		title: value?.title ?? '',
		services: getServiceListOptions(service, value ?? ''),
		serviceType,
	};
};

const ServiceOptionSelectField = ({ placeholder, entityId, suborderIndex, serviceType, disabled }: ServiceOptionSelectMajorFieldProps) => {
	const { control, setValue } = useFormContext();
	const { data } = useGetServicesQuery<Record<string, CatalogueService>>();
	const service = data?.[serviceType] ?? null;
	const fieldName = `suborders.${suborderIndex}.data.services.${entityId}.service` as const;

	const { ref } = useBackdrop();

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<Controller
					name={fieldName}
					control={control}
					render={({ field }) => {
						const newValue = field.value ? field.value : getInitialValue(field.value, service, serviceType);

						return (
							<ColorfulSelect
								closeOnSelect
								popupHeaderTitle="Виберіть тип послуги"
								popupHeaderClassName={styles.popupHeader}
								value={newValue}
								placeholder={placeholder}
								// @ts-ignore
								rootButton={(props) => <RootButton {...props} disabled={disabled} title={placeholder} />}
								options={service?.subCategories ?? []}
								onSelect={(v) => {
									setValue(`suborders.${suborderIndex}.data.services.${entityId}.category`, null);
									field.onChange({ ...v, serviceType });
								}}
							/>
						);
					}}
				/>
			</MediaQuery>

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectField;
