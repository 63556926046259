import clsx from 'clsx';
import Button from 'components/Button';
import SpinnerV2 from 'components/Spinner-v2';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { forwardRef, useCallback } from 'react';
import { ReactComponent as CheckIcon } from 'static/images/check.svg';

import OptionTag from './components/OptionTag';
import { useController } from './hooks/_useController';
import styles from './styles.module.css';
import type { IProps, MultipleInnerControl, Suggestion } from './types';
import { resolveDisplayValue, resolveValue } from './utils';

const AutocompleteInput = (props: IProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const {
		state,
		onClick,
		onFocus,
		options,
		refetch,
		isError,
		onKeyDown,
		isLoading,
		onUnselect,
		closeDropdown,
		onInputChange,
		resetSuggestion,
		hasReachedLimit,
	} = useController(props);

	const {
		name,
		label,
		onBlur,
		className,
		placeholder,
		type = 'text',
		readonly = false,
		disabled = false,
		leadingIcon: Icon,
		forceFocus = false,
		renderDropdownHeader,
		onClick: onInputClick,
	} = props;

	const { activeSuggestion, filteredSuggestions, showSuggestions, suggestion, userInput, hasFocus } = state;

	const handleRefetch = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		refetch?.();
	};

	const handleClick = (option: Suggestion | string) => (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onClick(option);
	};

	const handleCloseDropdown = useCallback(closeDropdown, []);

	const rootRef = useClickOutside<HTMLDivElement>(handleCloseDropdown);

	const renderIcon = !!Icon;
	const suggestionList = userInput ? filteredSuggestions : options;
	const renderList = suggestionList && suggestionList.length > 0 && (forceFocus || showSuggestions) && hasFocus && !hasReachedLimit;
	const isMultipleChoice = Array.isArray(suggestion) && !!props.multiple;
	const renderClearButton = !!userInput || (isMultipleChoice && suggestion.length > 0);
	const shouldRenderOptions = !isLoading;
	const renderChevronIcon = !renderClearButton;
	const noEvents = (disabled || readonly) && !onInputClick;

	const inputElement = (
		<input
			ref={ref}
			name={name}
			id={name}
			data-disabled={disabled}
			readOnly={readonly}
			className={clsx(
				{ [styles.input]: !isMultipleChoice, [styles.multipleInput]: isMultipleChoice, [styles.noEvents]: noEvents },
				'no-user-agent-autofill-bg',
			)}
			type={type}
			placeholder={placeholder}
			onChange={onInputChange}
			onKeyDown={onKeyDown}
			value={userInput}
			onClick={onInputClick}
			autoComplete="off"
			onBlur={onBlur}
			onFocus={onFocus}
		/>
	);

	const dropdownList = (
		<ul className={clsx(styles.dropdown, { [styles.asyncLoading]: !shouldRenderOptions })}>
			{shouldRenderOptions && renderDropdownHeader && renderDropdownHeader(suggestion)}
			{shouldRenderOptions &&
				suggestionList?.map((option, index) => {
					const matchesIndex = index === activeSuggestion;

					const matchesSuggestion = Array.isArray(suggestion)
						? suggestion.some((selectedSuggestion: Suggestion | string) => resolveValue(selectedSuggestion) === resolveValue(option))
						: resolveValue(suggestion) === resolveValue(option);

					return (
						<li key={index}>
							<button
								type="button"
								onClick={handleClick(option)}
								className={clsx(styles.option, 'color-primary-900', {
									[styles.active]: matchesIndex,
								})}
							>
								<span>{resolveDisplayValue(option)}</span>
								{matchesSuggestion && <CheckIcon className={styles.checkIcon} />}
							</button>
						</li>
					);
				})}

			{isLoading && (
				<li className={styles.spinner}>
					<SpinnerV2 />
				</li>
			)}

			{isError && (
				<li>
					<p className={styles.error}>Не вдалося завантажити опції...</p>
					<Button variant="small" text="Завантажити ще раз" onClick={handleRefetch} />
				</li>
			)}
		</ul>
	);

	return (
		<div
			ref={rootRef}
			className={clsx(styles.wrapper, className, {
				[styles.withLeadingIcon]: renderIcon,
				[styles.multiple]: isMultipleChoice,
				[styles.chevron]: renderChevronIcon,
			})}
		>
			{!!label && <label htmlFor={name}>{label}</label>}

			{isMultipleChoice && (
				<div
					data-disabled={disabled}
					className={clsx(styles.multipleSelectedOptions, {
						[styles.input]: isMultipleChoice,
						[styles.multiple]: suggestion.length > 0,
						[styles.empty]: suggestion.length === 0,
					})}
				>
					{suggestion.map((option, idx) => {
						const CustomOptionTag = (props as MultipleInnerControl).optionTagComponent;
						if (CustomOptionTag) {
							return (
								<CustomOptionTag key={idx} suggestion={option} onUnselect={() => onUnselect?.(option.value)} disabled={noEvents} />
							);
						}
						return <OptionTag key={idx} disabled={noEvents} title={option?.label} onUnselect={() => onUnselect?.(option.value)} />;
					})}

					{renderIcon && <Icon className={styles.leadingIcon} />}
					{!hasReachedLimit && inputElement}

					{renderList && dropdownList}
				</div>
			)}

			{renderIcon && !isMultipleChoice && <Icon className={styles.leadingIcon} />}
			{!isMultipleChoice && inputElement}
			{!isMultipleChoice && renderList && dropdownList}

			{renderClearButton && <button type="button" disabled={!!disabled} className={styles.button} onClick={resetSuggestion} />}
		</div>
	);
};

export default forwardRef(AutocompleteInput);
