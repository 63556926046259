import { AbilityBuilder, AbilityClass, AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';

type AppAbility = PureAbility<AbilityTuple, MatchConditions>;
const AppAbility = PureAbility as AbilityClass<AppAbility>;
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

export const defineAbilitiesFor = (permissions: Record<string, AnyArg>) => {
	const { can, build } = new AbilityBuilder(AppAbility);

	if (permissions?.manage === 'all') {
		can('manage', 'all');
		return build({ conditionsMatcher: lambdaMatcher });
	}

	if (permissions && typeof permissions === 'object') {
		Object.entries(permissions).forEach(([action, permissionList]) => {
			if (!Array.isArray(permissionList)) return;

			permissionList.forEach((permission) => {
				if (permission.includes('mine')) {
					const normalizedPermission = permission.replace('.mine', '');
					can(action, normalizedPermission);
				} else {
					can(action, permission);
				}
			});
		});
	}

	return build({ conditionsMatcher: lambdaMatcher });
};
