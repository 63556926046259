import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Can } from '../OrderAbility/provider';

interface ProtectedOrderRouteProps {
	children: React.ReactNode;
	I: string;
	an: string;
}

const ProtectedOrderRoute: React.FC<ProtectedOrderRouteProps> = ({ children, an, I }) => {
	const location = useLocation();
	const redirectUrl = location.pathname.replace('/split', '');

	return (
		<Can passThrough I={I} an={an}>
			{(can) => {
				if (!can) {
					return <Navigate to={redirectUrl} />;
				}

				return children;
			}}
		</Can>
	);
};

export default ProtectedOrderRoute;
