import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRefreshOrderLockedStatusQuery } from 'store/reducers/orders/ordersSliceApi';
import { logger } from 'utils/logger';

const RefreshOrderLockedStatus: React.FC<{ skip: boolean }> = ({ skip }) => {
	const { id: orderId } = useParams();
	const pollingInterval = skip ? 0 : 1000 * 60;

	const { isFetching, isUninitialized } = useRefreshOrderLockedStatusQuery(orderId, { pollingInterval, skip });

	useEffect(() => {
		if (isFetching && !isUninitialized) {
			logger.logPolling('🔄 Order locked status is refreshed at ' + format(new Date(), 'HH:mm:ss'));
		}
	}, [isFetching, isUninitialized]);

	return <></>;
};

export default RefreshOrderLockedStatus;
