import clsx from 'clsx';
import Button from 'components/Button';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';
import type { FilterValue, IProps } from './types';

const FastGroupFilters: React.FC<IProps> = ({ listOfFilters, disableAll = false, queryKey = 'segment', className }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const patchUrlSearchParams = (filters: FilterValue[]) => {
		const newUrlSearchParams = new URLSearchParams(searchParams);
		const currentFilters = newUrlSearchParams.getAll(queryKey);

		newUrlSearchParams.delete(queryKey);

		filters.forEach((filter) => {
			if (currentFilters.includes(filter)) {
				const index = currentFilters.indexOf(filter);
				currentFilters.splice(index, 1);
			} else {
				currentFilters.push(filter);
			}
		});

		currentFilters.forEach((value) => newUrlSearchParams.append(queryKey, value));

		setSearchParams(newUrlSearchParams);
	};

	return (
		<div className={clsx(styles.buttonsWrapper, className)}>
			{listOfFilters.map((filter, index) => {
				const [buttonDisplayName] = Object.keys(filter);
				const [values] = Object.values(filter);

				const allSearchQueries = searchParams.getAll(queryKey);
				const isCertainFilterApplied = values.some((query) => allSearchQueries.includes(query));
				const isActive = isCertainFilterApplied;

				return (
					<Button
						key={index}
						text={buttonDisplayName}
						disabled={disableAll}
						onClick={() => patchUrlSearchParams(values)}
						variant="small"
						className={clsx(styles.button, {
							[styles.active]: isActive,
						})}
					/>
				);
			})}
		</div>
	);
};

export default FastGroupFilters;
