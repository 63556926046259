import { createColumnHelper } from '@tanstack/react-table';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { SERVER_ENTITY_ID } from 'const';
import { useAppSelector } from 'hooks/redux';
import { ProductPrice } from 'models/price';
import { CatalogueProduct } from 'models/product/catalogue-product';
import { useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React, { useMemo } from 'react';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';

type PriceType = Pick<CatalogueProduct, 'prices' | 'id'> & { isBlank?: boolean };
type UsePriceTypeColumnsProps = {
	isTablet: boolean;
	isDesktop: boolean;
	suborderIndex?: number;
};
const columnHelper = createColumnHelper<PriceType>();
const commonCellSize = 110;

export const getPriceByPriceId = <TData extends { prices?: ProductPrice[] }>(entity: TData, id: string) => {
	return entity?.prices?.find((price) => price.typePrice.id === id)?.price ?? '0.00';
};

export const usePriceTypeColumns = ({ isDesktop, isTablet, suborderIndex }: UsePriceTypeColumnsProps) => {
	const { getValues } = useTypedOrderControllerFromContext();

	return useMemo(
		() => [
			columnHelper.accessor((row) => getPriceByPriceId(row, SERVER_ENTITY_ID.ProductEnterPriceType), {
				id: 'enterPrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Вхід.</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-100">
							{cell.getValue()}
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вхід.',
					getMobileHeaderCell: () => 'Вхід.',
					isVisibleOnDemandOnly: true,
				},
			}),
			columnHelper.accessor((row) => getPriceByPriceId(row, SERVER_ENTITY_ID.ProductMinimalPriceType), {
				id: 'minimalPrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Мін.</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-100">
							{cell.getValue()}
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Мін.',
					getMobileHeaderCell: () => 'Мін.',
					isVisibleOnDemandOnly: true,
				},
			}),
			columnHelper.accessor((row) => getPriceByPriceId(row, SERVER_ENTITY_ID.ProductWholeSalePriceType), {
				id: 'wholesalePrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Опт.</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-100">
							{cell.getValue()}
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Опт.',
					getMobileHeaderCell: () => 'Опт.',
					isVisibleOnDemandOnly: true,
				},
			}),
			columnHelper.accessor(() => null, {
				id: 'lastPrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ост ц.</HeaderCell>,
				cell: (cell) => {
					const suborder = getValues('suborders')[suborderIndex];
					const client = suborder.data?.client;
					const contract = suborder.data?.contract;
					const result = useAppSelector(ordersSliceApi.endpoints.getProductLastPrices.select(`${client?.value}:${contract?.value}`));

					const price = result[cell.row.original.id] ?? '—';

					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-100">
							{price}
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ост ц.',
					getMobileHeaderCell: () => 'Ост ц.',
					isVisibleOnDemandOnly: true,
				},
			}),
		],
		[isTablet, isDesktop, suborderIndex],
	);
};
