import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyUnlockOrderManuallyQuery } from 'store/reducers/orders/ordersSliceApi';

const UnlockOrderOnLeave: React.FC = () => {
	const { id: orderId } = useParams();

	const [unlockOrderAsync] = useLazyUnlockOrderManuallyQuery();

	useEffect(() => {
		return () => {
			unlockOrderAsync(orderId).unwrap();
		};
	}, []);

	return <></>;
};

export default UnlockOrderOnLeave;
