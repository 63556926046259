import Spinner from 'components/Spinner';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import { Client } from 'models/IOrder';
import ClientCard from 'pages/ClientPage/ClientCard';
import React, { lazy, Suspense, useCallback, useRef } from 'react';
import { unstable_useBlocker as useBlocker, useParams } from 'react-router-dom';
import { ReactComponent as RightChevronIcon } from 'static/images/chevron-right.svg';
import { useGetClientByIdQuery, useGetSegmentsQuery } from 'store/reducers/clients/clientsSliceApi';

import { isRouteBlacklistedForUnsafeNavigation } from '../OrderController/lib/utils';

const AlertDialogue = lazy(() => import('components/AlertDialogue'));
const AlertDialogueContent = lazy(() => import('components/AlertDialogue/components/AlertDialogueContent'));
const AlertDialogueControlButton = lazy(() => import('components/AlertDialogue/components/AlertDialogueControlButton'));
const AlertDialogueFooter = lazy(() => import('components/AlertDialogue/components/AlertDialogueFooter'));
const AlertDialogueHeader = lazy(() => import('components/AlertDialogue/components/AlertDialogueHeader'));
const AlertDialogueMessage = lazy(() => import('components/AlertDialogue/components/AlertDialogueMessage'));

const OrderClientCardModule: React.FC = () => {
	const { clientId } = useParams();
	const needBlock = useRef(true);

	const getSegmentsRequest = useGetSegmentsQuery(undefined);
	const getClientRequest = useGetClientByIdQuery<{ data: Client; isLoading: boolean }>(clientId);

	const canBlockNavigation = useCallback(
		({ currentLocation, nextLocation }) => {
			const isBlocked =
				currentLocation.pathname !== nextLocation.pathname &&
				isRouteBlacklistedForUnsafeNavigation(nextLocation.pathname) &&
				needBlock.current;

			return isBlocked;
		},
		[needBlock.current],
	);
	const blocker = useBlocker(canBlockNavigation);

	const onSubmit = useStopPropagationCallback<HTMLButtonElement>(() => {
		needBlock.current === false;
		blocker.proceed();
	});
	const onClose = useStopPropagationCallback<HTMLButtonElement>(blocker.reset);
	const stopBubbling = useStopPropagationCallback();

	if (getClientRequest.isLoading || getSegmentsRequest.isLoading) {
		return <PageContentSkeleton />;
	}

	return (
		<>
			<div onClick={stopBubbling}>
				<ClientCard id={clientId} />
			</div>

			<Suspense fallback={<Spinner />}>
				{blocker.state === 'blocked' ? (
					<AlertDialogue onOutsideClick={onClose}>
						<AlertDialogueHeader onClose={onClose}>Перехід на сторінку</AlertDialogueHeader>
						<AlertDialogueContent>
							<AlertDialogueMessage>
								Ви збираєтеся перейти на іншу сторінку, яка не має відношення до сторінки заявки.
							</AlertDialogueMessage>
							<AlertDialogueMessage>Якщо продовжите, то незбережені зміни буде втрачено.</AlertDialogueMessage>
							<AlertDialogueMessage>Все одно перейти?</AlertDialogueMessage>
						</AlertDialogueContent>
						<AlertDialogueFooter>
							<AlertDialogueControlButton variant="cancel" onClick={onClose}>
								Скасувати
							</AlertDialogueControlButton>
							<AlertDialogueControlButton variant="submit" onClick={onSubmit}>
								<RightChevronIcon />
								Перейти
							</AlertDialogueControlButton>
						</AlertDialogueFooter>
					</AlertDialogue>
				) : null}
			</Suspense>
		</>
	);
};

export default OrderClientCardModule;
