import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { CatalogueProduct } from 'models/product/catalogue-product';
import React, { useMemo } from 'react';
import { ReactComponent as ChevronDownIcon } from 'static/images/chevron-down.svg';
import { useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';

import styles from './styles.module.css';

interface SearchProductAvailabilityTableProps {
	title?: React.ReactNode;
	className?: string;
	product: CatalogueProduct;
	stockId: string;
}
interface SearchProductResultItem {
	id: string;
	stock: string;
	totalAvailableLeftovers: string;
	freeLeftovers: string;
	reserves: string;
	isCurrent: boolean;
}

const columnHelper = createColumnHelper<SearchProductResultItem>();

const SearchProductAvailabilityTable: React.FC<SearchProductAvailabilityTableProps> = ({ title, product, stockId, className }) => {
	const { data: allStocks } = useGetWarehousesQuery(undefined);

	const accordion = useBoolean();
	const accordionToggle = useStopPropagationCallback(accordion.toggle);

	const data = useMemo<SearchProductResultItem[]>(() => {
		if (!allStocks || !product) return [];

		return allStocks
			.map((stock) => {
				const freeLeftovers = product.freeLeftovers.find((item) => item.stock.id === stock.value)?.amount || '0';
				const reserves = product.reserves.find((item) => item.stock.id === stock.value)?.amount || '0';

				const totalAvailableLeftovers = String(Number(freeLeftovers) + Number(reserves));

				if (Number(totalAvailableLeftovers) <= 0 || isNaN(Number(totalAvailableLeftovers))) {
					return null;
				}

				return {
					id: stock.value,
					freeLeftovers,
					reserves,
					stock: stock.label,
					totalAvailableLeftovers,
					isCurrent: stock.value === stockId,
				};
			})
			.filter(Boolean);
	}, [allStocks, product, stockId]);

	const columns = useMemo(() => {
		return [
			columnHelper.accessor((row) => row.stock, {
				id: 'stock',
				header: () => (
					<HeaderCell className={clsx(styles.cell, styles.alignStart)}>
						<span>Склад</span>
						<span>{`(${product.unit.title})`}</span>
					</HeaderCell>
				),
				cell: (cell) => (
					<RegularCell className={clsx(styles.alignStart, { [styles.isCurrent]: cell.row.original.isCurrent })}>
						{cell.getValue()}
					</RegularCell>
				),
			}),
			columnHelper.accessor((row) => row.totalAvailableLeftovers, {
				id: 'total',
				header: () => (
					<HeaderCell className={clsx(styles.cell)}>
						<span>Загальний</span>
						<span>{`(${product.unit.title})`}</span>
					</HeaderCell>
				),
				cell: (cell) => <RegularCell className={clsx({ [styles.isCurrent]: cell.row.original.isCurrent })}>{cell.getValue()}</RegularCell>,
			}),
			columnHelper.accessor((row) => row.freeLeftovers, {
				id: 'freeLeftovers',
				header: () => (
					<HeaderCell className={clsx(styles.cell)}>
						<span>Вільний</span>
						<span>{`(${product.unit.title})`}</span>
					</HeaderCell>
				),
				cell: (cell) => <RegularCell className={clsx({ [styles.isCurrent]: cell.row.original.isCurrent })}>{cell.getValue()}</RegularCell>,
			}),
			columnHelper.accessor((row) => row.reserves, {
				id: 'reserves',
				header: () => (
					<HeaderCell className={clsx(styles.cell)}>
						<span>В резерві</span>
						<span>{`(${product.unit.title})`}</span>
					</HeaderCell>
				),
				cell: (cell) => <RegularCell className={clsx({ [styles.isCurrent]: cell.row.original.isCurrent })}>{cell.getValue()}</RegularCell>,
			}),
		];
	}, [product]);

	const table = useReactTable({
		columns,
		data,
		manualPagination: true,
		manualSorting: true,
		getCoreRowModel: getCoreRowModel<SearchProductResultItem>(),
		columnResizeMode: 'onChange',
	});

	const hasAvailableProductsOnAnyStock = data.length > 0;

	return (
		<div className={styles.availability}>
			<button type="button" onClick={accordionToggle} className={clsx(styles.expandButton, className)}>
				{!!title ? title : <span className={clsx(styles.accordionTitle)}>Наявність на складах</span>}
				<ChevronDownIcon className={clsx({ [styles.rotate]: accordion.isOn })} />
			</button>

			<div className={clsx(styles.accordion, { [styles.expanded]: accordion.isOn })}>
				{hasAvailableProductsOnAnyStock && (
					<table className={styles.table}>
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<th key={header.id} colSpan={header.colSpan} className={clsx('text-sx-medium color-grey-600')}>
												{!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
											</th>
										);
									})}
								</tr>
							))}
						</thead>

						<tbody>
							{table.getRowModel().rows.map((row) => {
								return (
									<tr
										key={row.id}
										className={clsx(styles.tableRow, 'text-sx-regular', {
											[styles.isCurrent]: row.original.isCurrent,
										})}
									>
										{row.getVisibleCells().map((cell) => {
											return <td key={cell.column.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
				{!hasAvailableProductsOnAnyStock && <div className={clsx(styles.empty)}>Залишків цього товару немає на жодному складі...</div>}
			</div>
		</div>
	);
};

export default SearchProductAvailabilityTable;
