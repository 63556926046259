import type { MultipleStringControllerProps } from 'components/SearchParamsController/MultipleStringController';
import MultipleStringController from 'components/SearchParamsController/MultipleStringController';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Case } from 'static/images/case.svg';

import SearchableServerSideFilter from '../components/SearchableServerSideFilter';
import SingleFilterWrapper from '../components/SingleFilterWrapper';
import styles from '../style.module.css';

type ByClientFilterProps = Pick<MultipleStringControllerProps, 'queryKey'> & {
	title?: string;
	icon?: React.ReactElement;
	placeholder?: string;
	queryTip?: string;
};

const ByClientFilter: React.FC<ByClientFilterProps> = ({
	queryKey,
	title = 'Клієнт',
	icon = <Case />,
	placeholder = 'Введіть імʼя клієнта',
	queryTip = 'Шукати за імʼям',
}) => {
	const [searchParams] = useSearchParams();
	const isExpanded = searchParams.has(queryKey);

	return (
		<MultipleStringController
			queryKey={queryKey}
			render={({ value, onChange, activeFiltersCount, indeterminateFiltersCount }) => {
				return (
					<SingleFilterWrapper
						activeFiltersCount={activeFiltersCount}
						indeterminateFiltersCount={indeterminateFiltersCount}
						expanded={isExpanded}
						title={title}
						{...(!!icon && { icon })}
					>
						<span className={styles.subTitle}>{queryTip}</span>

						<div className={styles.checkboxGroupWrapper}>
							<SearchableServerSideFilter value={value} onChange={onChange} placeholder={placeholder} />
						</div>
					</SingleFilterWrapper>
				);
			}}
		/>
	);
};

ByClientFilter.displayName = MultipleStringController.displayName;

export default ByClientFilter;
