import type { ColumnItem, VisibilityModel } from 'components/Table/lib/table/types/table';
import { useEffect, useState } from 'react';
import { localStorageService } from 'services/localStorageService';

export interface UseAdjustableColumnsProps {
	saveConfigKey: string;
	initial?: VisibilityModel;
	skipSaveColumns?: string[];
}
const fallback = [];

export const useAdjustableColumns = <TData>(
	columns: ColumnItem<TData>[],
	{ saveConfigKey, initial, skipSaveColumns = fallback }: UseAdjustableColumnsProps,
) => {
	const key = 'visibility::' + saveConfigKey;
	const [visibilityModel, setVisibilityModel] = useState<VisibilityModel>(hydrateState(key, initial, skipSaveColumns));

	useEffect(() => {
		const newVisibilityModel = { ...visibilityModel };
		skipSaveColumns.forEach((column) => {
			if (Object.hasOwn(newVisibilityModel, column)) {
				delete newVisibilityModel[column];
			}
		});

		localStorageService.save(key, newVisibilityModel);
	}, [visibilityModel, skipSaveColumns]);

	return { visibilityModel, setVisibilityModel, visibilityModelSaveConfigKey: key };
};

function hydrateState(
	key: UseAdjustableColumnsProps['saveConfigKey'],
	initial: VisibilityModel,
	skipSaveColumns: UseAdjustableColumnsProps['skipSaveColumns'],
) {
	return function hydrate(): VisibilityModel {
		const visibilityModel = localStorageService.load<VisibilityModel>(key);

		if (visibilityModel && Object.keys(visibilityModel ?? {}).length > 0) {
			skipSaveColumns.forEach((column) => {
				if (visibilityModel[column]) {
					delete visibilityModel[column];
				}
			});

			return visibilityModel;
		}
		const fallbackModel = { ...initial };

		return fallbackModel;
	};
}
