import { OptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import { StockIdSchema } from 'models/common/uuid';
import { z } from 'zod';

export const StockSchema = z.object({
	id: StockIdSchema,
	title: z.string(),
});

export const StockOptionSchema = standardizeOption<typeof OptionSchema, Stock>(OptionSchema, ({ id, title }) => {
	if (!id) {
		return null;
	}

	return {
		label: title,
		value: id,
	};
});

export type Stock = z.infer<typeof StockSchema>;
