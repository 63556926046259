import clsx from 'clsx';
// import Calendar from 'components/Calendar';
import CameraButton from 'components/CameraButton';
import FileUploadField from 'components/FileUploadField';
import { ClientFormEdit } from 'components/FormComponents';
// import FieldGroup from 'components/FormComponents/FieldGroup';
import ProgressBadge from 'components/ProgressBadge';
import RegistryDateBadge from 'components/RegistryDateBadge';
import { breakPoints } from 'const';
import React from 'react';
import MediaQuery from 'react-responsive';

import styles from './style.module.css';

const noop = () => {};

const ClientContacts: React.FC<{ clientType: string }> = ({ clientType }) => {
	return (
		<div className={clsx(styles.contactsWrapper, 'hide-scroll-bar')}>
			<ClientFormEdit clientType={clientType} onSubmit={noop} />

			{/* <MediaQuery maxWidth={breakPoints.LAPTOP_SM - 1} minWidth={breakPoints.MOBILE}>
				<FieldGroup>
					<Calendar className={styles.calendar} />
				</FieldGroup>
			</MediaQuery> */}

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<FileUploadField />

				<CameraButton />

				<div className={styles.progressBadgeWrapper}>
					<ProgressBadge forceDisplayProgressCompleteIcon hideProgressBar title="Паспорт" />
					<ProgressBadge forceDisplayProgressCompleteIcon title="Паспорт" />
				</div>

				<RegistryDateBadge date={new Date()} />
			</MediaQuery>

			<div className="safe-area-bottom" />
		</div>
	);
};

export default ClientContacts;
