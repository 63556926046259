import clsx from 'clsx';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import RefreshActions from 'components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions';
import FullScreenGate from 'components/OrderPageComponents/(VisibilityGates)/FullScreenGate';
import Payable from 'components/OrderPageComponents/Payable';
import { breakPoints } from 'const';
import { Can } from 'pages/OrderRework/OrderAbility/provider';
import { calculateTotals, calculateTotalServicesCost } from 'pages/OrderRework/OrderController/lib/utils';
import React from 'react';
import { Controller } from 'react-hook-form';
import MediaQuery, { useMediaQuery } from 'react-responsive';
// import LockIcon from 'static/images/lock.svg';
import MultiFileICon from 'static/images/file-in.svg';
import { formatNumberToUAH, kVolumeFormatter, kWeightFormatter } from 'utils/shared';

import { ProductInternalModelState, ServiceInternalModelState, useTypedOrderControllerFromContext } from '../../../OrderController';
import styles from './styles.module.css';

interface StatsProps {
	isFullScreen: boolean;
	activeTab: number;
	products: ProductInternalModelState[];
	services: ServiceInternalModelState[];
	isReserved?: boolean;
	onSave: VoidCallback;
	onReserve?: VoidCallback;
	isPriceChangeModeOn?: boolean;
}

export const Stats: React.FC<StatsProps> = ({ services, activeTab, products, isReserved, isFullScreen, isPriceChangeModeOn, onSave }) => {
	const isTablet = useMediaQuery({ minWidth: `${breakPoints.MOBILE}px`, maxWidth: `${breakPoints.LAPTOP_MD - 1}px` });
	const { control, getValues, setValue } = useTypedOrderControllerFromContext();
	const totals = calculateTotals(products);
	const totalServicesCost = calculateTotalServicesCost(services);

	const formattedWeight = `Вага - ${kWeightFormatter(totals?.weight || 0)}`;
	const formattedVolume = `Об’єм - ${kVolumeFormatter(totals?.volume || 0)}`;
	const formattedPrice = formatNumberToUAH((totals?.sum || 0) + totalServicesCost);

	return (
		<>
			<FullScreenGate isFullScreen={isFullScreen}>
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className={styles.refreshActionsWrapper}>
						<RefreshActions />
					</div>
				</MediaQuery>
			</FullScreenGate>

			<div className={styles.totalPriceWrapper}>
				<FullScreenGate isFullScreen={isFullScreen}>
					<MediaQuery minWidth={breakPoints.MOBILE}>
						<div className={styles.chipsAndPriceWrapper}>
							<div className={styles.chipsWrapper}>
								<Chip title={formattedWeight} />
								<Chip title={formattedVolume} />
							</div>

							<Payable price={formattedPrice} className={styles.totalPrice} />
						</div>
					</MediaQuery>
					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<div className={styles.chipsWrapper}>
							<Chip title={formattedWeight} />
							<Chip title={formattedVolume} />
						</div>

						<Payable price={formattedPrice} className={styles.totalPrice} />
					</MediaQuery>

					<div className={styles.suborderControlsWrapper}>
						<>
							<Can passThrough I="change" an={`order.${activeTab}.isPaid`}>
								{(can) => (
									<Controller
										name={`suborders.${activeTab}.data.isPaid`}
										control={control}
										render={({ field }) => {
											const handleChange = () => {
												const isWithoutPayment = getValues(`suborders.${activeTab}.data.isWithoutPayment`);
												if (!field.value && isWithoutPayment) {
													setValue(`suborders.${0}.data.isWithoutPayment`, false);
												}
												field.onChange(!field.value);
											};

											return (
												<Checkbox
													label="З оплатою"
													checked={field.value}
													onChange={handleChange}
													disabled={!can}
													className={clsx(styles.checkboxField, { disabled: !can })}
												/>
											);
										}}
									/>
								)}
							</Can>
							<Can passThrough I="change" an={`order.${activeTab}.isWithoutPayment`}>
								{(can) => (
									<Controller
										name={`suborders.${activeTab}.data.isWithoutPayment`}
										control={control}
										render={({ field }) => {
											const handleChange = () => {
												const isPaid = getValues(`suborders.${activeTab}.data.isPaid`);
												if (!field.value && isPaid) {
													setValue(`suborders.${activeTab}.data.isPaid`, false);
												}
												field.onChange(!field.value);
											};

											return (
												<Checkbox
													label="Без оплати"
													checked={field.value}
													onChange={handleChange}
													disabled={!can}
													className={clsx(styles.checkboxField, { disabled: !can })}
												/>
											);
										}}
									/>
								)}
							</Can>
						</>

						<Button
							text={isTablet ? '' : 'Зберегти'}
							variant="rounded"
							onClick={onSave}
							background="var(--pink)"
							icon={MultiFileICon}
							disabled={isReserved || isPriceChangeModeOn}
							className={clsx({ [styles.button]: isTablet })}
						/>
						{/* {activeTab > 0 && (
							<Button
								onClick={onReserve}
								text={isTablet ? '' : isReserved ? 'В резерві' : `Резерв заявки ${activeTab}`}
								variant="rounded"
								background="var(--error-50)"
								icon={LockIcon}
								disabled={isPriceChangeModeOn}
								className={clsx(styles.reserv, { [styles.active]: isReserved, [styles.button]: isTablet })}
							/>
						)} */}
					</div>
				</FullScreenGate>
			</div>
		</>
	);
};
