import 'static/styles/style.css';

import clsx from 'clsx';
import Button from 'components/Button';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Chart from 'static/images/chart.svg';
import { translateNameOfBadge } from 'utils/orders';
import { isObject, isString } from 'utils/type-guards';

import styles from './style.module.css';
import type { IProps } from './types';

const SegmentDropDownFilter: React.FC<IProps> = ({
	listOfFilters,
	disableAll = false,
	queryKey = 'segment[]',
	title = 'Сегмент',
	multiple = false,
	className,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [openDropDown, setOpenDropDown] = useState(false);
	const filtersList = listOfFilters ? [{ title: 'Усі', id: 'Усі', type: 'all' }, ...listOfFilters] : [{ title: 'Усі', id: 'Усі', type: 'all' }];

	const closeDropDown = useCallback(() => {
		if (openDropDown) {
			setOpenDropDown(false);
		}
	}, [openDropDown]);

	const handleClickSegmentBtn = () => {
		setOpenDropDown(!openDropDown);
	};

	const rootRef = useClickOutside<HTMLDivElement>(closeDropDown);

	const patchUrlSearchParams = (filter: string) => {
		const newUrlSearchParams = new URLSearchParams(searchParams);

		if (multiple) {
			if (filter === 'Усі') {
				newUrlSearchParams.delete(queryKey);
			} else {
				const currentFilters = newUrlSearchParams.getAll(queryKey);

				if (currentFilters.includes(filter)) {
					newUrlSearchParams.delete(queryKey);
					currentFilters.splice(currentFilters.indexOf(filter), 1);
					currentFilters.forEach((value) => newUrlSearchParams.append(queryKey, value));
				} else {
					if (!newUrlSearchParams.has(queryKey)) {
						newUrlSearchParams.set(queryKey, filter);
					} else {
						newUrlSearchParams.append(queryKey, filter);
					}
				}
			}
		} else {
			if (filter === 'Усі') {
				newUrlSearchParams.delete(queryKey);
			} else {
				newUrlSearchParams.set(queryKey, filter);
			}
		}

		setSearchParams(newUrlSearchParams.toString());
	};

	const handleFilterChange = (filter: string) => () => {
		patchUrlSearchParams(filter);

		if (!multiple) {
			closeDropDown();
		}
	};

	return (
		<div className={clsx(styles.filtersWrapper, className)} ref={rootRef}>
			<div data-wrapper className={styles.buttonsWrapper}>
				<Button
					icon={Chart}
					text={title}
					disabled={disableAll}
					variant="small"
					onClick={handleClickSegmentBtn}
					className={clsx(styles.button)}
				/>
				{openDropDown && (
					<div data-dropdown className={styles.dropDown}>
						{filtersList.map((item, index) => {
							const value = isString(item) ? item : isObject(item) && 'value' in item ? item.value : item?.id;
							const text = isString(item) ? translateNameOfBadge(item) : isObject(item) && 'label' in item ? item.label : item?.title;

							const isAllFiltersApplied = !searchParams.has(queryKey) && index === 0;
							const isCertainFilterApplied = searchParams.getAll(queryKey).includes(value);
							const isActive = isAllFiltersApplied || isCertainFilterApplied;

							return (
								<div className={styles.singleSegment} key={value}>
									<Checkbox value={value} onChange={handleFilterChange(value)} checked={isActive} />
									<p>{text}</p>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default SegmentDropDownFilter;
