import clsx from 'clsx';
import Input from 'components/Input';
import Payable from 'components/OrderPageComponents/Payable';
import { breakPoints } from 'const';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import DeleteIcon from 'static/images/delete.svg';
import { replaceComaWithDot } from 'utils/inputs';
import { formatNumberToUAH, toFixed } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps } from './types';

const QuantitySelector: React.FC<IProps> = ({
	priceFormatter = formatNumberToUAH,
	measureUnits,
	onMeasureUnitChange,
	onQuantityChange,
	totalPrice,
	className,
	quantity,
	unit,
}) => {
	const isTabletOnly = useMediaQuery({ minWidth: `${breakPoints.MOBILE}px`, maxWidth: `${breakPoints.LAPTOP_MD - 1}px` });
	const formattedPrice = priceFormatter(totalPrice);
	const units = measureUnits ?? [unit];
	const isInt = !!unit?.isInt;

	const handleClearInputIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		const targetElement = e.target as HTMLElement;
		const tagName = targetElement.tagName;

		if (tagName !== 'IMG') return;

		if (quantity === 0) return;

		onQuantityChange?.(0);
	};

	return (
		<div data-quantity-selector className={clsx(styles.wrapper, className)}>
			{!isTabletOnly && (
				<strong className={styles.title}>
					Укажіть кількість <span className={styles.selectedMeasureUnit}>{unit.title}</span>
				</strong>
			)}

			<div className={styles.subWrapper} onClick={handleClearInputIconClick}>
				<Input
					type="text"
					{...(!isInt && { onKeyDown: replaceComaWithDot })}
					value={String(quantity)}
					setValue={onQuantityChange}
					className={clsx(styles.input, { [styles.tablet]: isTabletOnly })}
					icon={DeleteIcon}
					iconPosition="trailing"
					onBlur={() => {
						const numberedValue = Number(quantity);

						if (!numberedValue) {
							onQuantityChange?.(isInt ? '0' : '0.00');
						} else {
							onQuantityChange?.(toFixed(quantity, { isInt, precise: 2, strictPrecision: true }));
						}
					}}
				/>

				<ul className={styles.measureUnitsWrapper}>
					{units.map((measureUnit, index) => {
						const isActive = measureUnit.id === unit.id;

						return (
							<li key={index}>
								<button
									className={clsx('text-sm-medium', styles.button, { [styles.active]: isActive, 'text-sm-semibold': isActive })}
									type="button"
									onClick={() => onMeasureUnitChange(measureUnit)}
								>
									{measureUnit.title}
								</button>
							</li>
						);
					})}
				</ul>

				<Payable price={formattedPrice} className={styles.price} />
			</div>
		</div>
	);
};

export default QuantitySelector;
