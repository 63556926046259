import clsx from 'clsx';
import FormField from 'components/FormComponents/FormField';
import React, { ComponentProps, Fragment } from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

export const DynamicArrayField = ({
	name,
	renderComponent,
	noControls = false,
	fields,
	disableAppend = false,
	disableRemove = false,
	onAppendField,
	onRemoveField,
}: IProps) => {
	return (
		<div className={styles.field}>
			{fields.map((field, index) => {
				const path = `${name}.${index}`;

				return <Fragment key={field.id}>{renderComponent({ path, field, index })}</Fragment>;
			})}

			{!noControls && (
				<FormField
					hiddenLabel
					label="Додати"
					name="action-buttons"
					renderLabel={(classNames: string) => <span className={classNames}>Дія</span>}
					fieldClassName={clsx('dynamic-array-field-controls', styles.withHiddenLabel)}
					component={() => (
						<>
							<ActionButton className={clsx({ [styles.disabled]: disableAppend })} onClick={onAppendField}>
								Додати
							</ActionButton>
							<ActionButton className={clsx({ [styles.disabled]: disableRemove })} onClick={onRemoveField}>
								Видалити
							</ActionButton>
						</>
					)}
				/>
			)}
		</div>
	);
};

function ActionButton({ className, children, onClick, ...restProps }: ComponentProps<'button'>) {
	return (
		<button type="button" className={clsx('text-sm-semibold color-primary-700', styles.button, className)} onClick={onClick} {...restProps}>
			{children}
		</button>
	);
}
