import clsx from 'clsx';
import ProgressBar from 'components/ProgressBar';
import { useBoolean } from 'hooks/useBoolean';
import React, { forwardRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as ProgressCompleteIcon } from 'static/images/check-rounded.svg';
import { ReactComponent as DeleteIcon } from 'static/images/trash.svg';
import { ReactComponent as MainIcon } from 'static/images/upload-file.svg';
import { hasTouchScreen } from 'utils/devices';
import { calculateProgress, getProgressBarFillColor } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps } from './types';

const ProgressBadge = (
	{
		title,
		subTitle,
		icon = MainIcon,
		className,
		forceDisplayProgressCompleteIcon = false,
		hideProgressBar = false,
		hideProgressBarOnComplete = false,
		readonly = false,
		start = 0,
		end = 0,
		prefix,
		precision,
		error = false,
		isActive = false,
		onTryAgain,
		onDelete,
		onClick,
		coloredProgressBar = false,
		excludeProgressBar = false,
	}: IProps,
	ref: React.ForwardedRef<HTMLDivElement>,
) => {
	const progress = calculateProgress(start, end, { precision });
	const fillColor = coloredProgressBar ? getProgressBarFillColor(progress) : 'var(--primary-600)';
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top' });
	const visible = useBoolean();

	const Icon = icon;
	const renderCompleteIcon = forceDisplayProgressCompleteIcon || (progress === 100 && !error && !isActive);
	const renderDeleteIcon = !readonly && (error || (progress === 100 && isActive));
	const renderProgress = !excludeProgressBar && ((!error && progress < 100 && !hideProgressBar) || !hideProgressBarOnComplete);
	const renderSubtitle = !!subTitle && !error;
	const renderActionButton = error || progress > 0;

	return (
		<div
			data-progress-badge
			ref={ref}
			onClick={onClick}
			className={clsx(styles.progressBadgeWrapper, className, {
				[styles.center]: hideProgressBar || hideProgressBarOnComplete,
				[styles.active]: isActive,
				[styles.error]: error,
			})}
			onTouchStart={(e) => {
				e.stopPropagation();
				visible.open();
				return false;
			}}
			onTouchEnd={visible.close}
			onTouchCancel={visible.close}
			onContextMenu={(e) => {
				e.preventDefault();
			}}
		>
			<Icon className={styles.mainIcon} />

			<div className={styles.contentWrapper}>
				<p
					ref={setReferenceElement}
					{...(!hasTouchScreen() && { onMouseEnter: () => visible.open(), onMouseLeave: () => visible.close() })}
					className={clsx('text-sm-medium', 'color-grey-700', 'line-clamp', styles.title)}
				>
					{title}
				</p>

				{renderSubtitle && <p className={clsx('text-sm-regular', 'color-grey-600', 'line-clamp', styles.subTitle)}>{subTitle}</p>}

				{error && (
					<>
						<p className={clsx('text-sm-medium', styles.subTitle)}>Помилка завантаження</p>
						<button type="button" className={clsx('text-sm-semibold', styles.tryAgain)} onClick={onTryAgain}>
							Спробувати ще раз
						</button>
					</>
				)}

				{renderProgress && <ProgressBar progress={progress} fill={fillColor} prefix={prefix} />}
			</div>

			{renderActionButton && (
				<button type="button" className={styles.actionButton} onClick={onDelete}>
					{renderDeleteIcon && <DeleteIcon className={styles.deleteIcon} />}
					{renderCompleteIcon && <ProgressCompleteIcon className={styles.progressCompleteIcon} />}
				</button>
			)}

			{visible.isOn &&
				createPortal(
					<div ref={setPopperElement} style={popperStyles.popper} {...attributes.popper} className="tooltip">
						{title}
					</div>,
					document.querySelector('#portal'),
				)}
		</div>
	);
};

export default forwardRef(ProgressBadge);
