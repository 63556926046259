import clsx from 'clsx';
import AvatarWithOnlineStatus from 'components/AvatarWithOnlineStatus';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import { ClientContacts, ClientProfileDetails, ClientProfileTopBar, PaymentProgressTile } from 'components/ClientProfilePage/';
import { ColorfulSelectField } from 'components/FormComponents/Inputs/inputs';
import ProgressBadge from 'components/ProgressBadge';
import Spacer from 'components/Spacer';
import ClientComponent from 'components/Table/TableComponents/ClientComponent';
import { breakPoints, CLIENT_PAGE_FILTER_KEYS, ROUTES_URLS } from 'const';
import { Client } from 'models/client';
import React, { lazy, Suspense, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DelayIcon } from 'static/images/delay.svg';
import EyeIcon from 'static/images/eye.svg';
import Folder from 'static/images/folder-check.svg';
import { ReactComponent as UploadIcon } from 'static/images/upload-file.svg';
import { useGetClientByIdQuery, useGetSegmentsQuery } from 'store/reducers/clients/clientsSliceApi';
import { formatNumberToUAH, getColorByClientState, prepareUrl } from 'utils/shared';

import { getProgressColor } from './getProgressColor';
import styles from './styles.module.css';
import { formatPlural, getOrderPreFilterParamsString, hydrateClientFormState } from './utils';

const ActionButton = lazy(() => import('layouts/PageLayout/ActionButton'));
const BottomPanel = lazy(() => import('layouts/PageLayout/BottomPanel'));
const FileUploadField = lazy(() => import('components/FileUploadField'));
const Button = lazy(() => import('components/Button'));

const ClientCard: React.FC<{ id: string }> = ({ id }) => {
	const navigate = useNavigate();

	const { data: segments = [] } = useGetSegmentsQuery(undefined);
	const { data: client = {} } = useGetClientByIdQuery<{ data: Client }>(id);
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.LAPTOP_SM}px)` });

	const type = client?.segment?.type ?? null;
	const form = useForm({
		defaultValues: {
			...hydrateClientFormState(client),
			segment: segments?.find((option) => option.id === client?.segment.id) ?? { title: '', id: '' },
		},
	});

	const handleNavigateToDocuments = () => {
		navigate(`/documents/${client.id}`);
	};

	const handleClientBillsPreview = () => {
		const url = prepareUrl(ROUTES_URLS.ORDERS);
		const searchParams = window.location.search;
		const urlWithSearchParams = url + searchParams + (searchParams ? '&' : '?') + `client${encodeURIComponent('[]')}=${client.id}`;

		navigate(urlWithSearchParams);
	};

	const handlePaymentInfoClick = (queryValue: string) => () => {
		const params = getOrderPreFilterParamsString({ clientId: client.id, queryValue });
		navigate(prepareUrl(ROUTES_URLS.ORDERS) + params);
	};

	const crumbs: Breadcrumb[] = useMemo(
		() => [
			{ label: 'Клієнти', href: prepareUrl(ROUTES_URLS.CLIENTS) },
			{ label: client?.name ?? '' },
			{
				label: (
					<div className={styles.clientBreadCrumb}>
						<AvatarWithOnlineStatus isOnline fallback name={client?.name ?? ''} size="32px" />
						<ClientComponent cursor="pointer" client={client?.name ?? ''} />
					</div>
				),
				asBackLinkCrumbOnly: true,
			},
		],
		[client],
	);

	const spacerSize = isMobile ? '20px' : '31px';
	const clientStateBadgeColor = getColorByClientState(client?.status ?? 'white');

	return (
		<FormProvider {...form}>
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} className={styles.breadcrumbs}>
					<ColorfulSelectField disabled name="segment" options={segments} className="inside-breadcrumbs" defaultValue={client.segment} />
				</BreadCrumbs>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<BottomPanel className={styles.mobileBottomPanel}>
						<ActionButton text="Переглянути рахунки" onClick={handleClientBillsPreview} icon={EyeIcon} />
					</BottomPanel>
				</MediaQuery>

				<div className={styles.pageMainLayout}>
					<div className={styles.topBar}>
						<ClientProfileTopBar
							actionsSlot={
								<div className={styles.btnsWrapper}>
									<Button
										id="clients-top-bar-see-all-button"
										text="Документи"
										onClick={handleNavigateToDocuments}
										icon={Folder}
										variant="rounded"
										background={'var(--primary-500)'}
										hoverBg="#000"
									/>
									<Button
										id="clients-top-bar-see-all-button"
										text="Переглянути рахунки"
										onClick={handleClientBillsPreview}
										icon={EyeIcon}
										variant="rounded"
										background={'#d71b87'}
										hoverBg="#000"
									/>
								</div>
							}
						/>
					</div>

					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<div className={styles.clientProfileDetailsContainer}>
							<ClientProfileDetails title="Загальна сума закупок" totalAmount={client?.totalSum} since={client?.firstRealization}>
								<div className={styles.clientStateBadge} style={{ backgroundColor: clientStateBadgeColor }} />

								<div className={styles.badgeContainer}>
									<ProgressBadge
										readonly
										forceDisplayProgressCompleteIcon
										coloredProgressBar
										title={`Доступно ${formatNumberToUAH(client?.available?.availableSum)}`}
										subTitle={`Борг - ${formatNumberToUAH(client?.available?.debt)} - Ліміт ${formatNumberToUAH(
											client?.available?.limit,
										)}`}
										start={client?.available?.debt}
										end={client?.available?.limit}
										icon={UploadIcon}
										className={styles.progressBadge}
									/>

									<ProgressBadge
										readonly
										excludeProgressBar
										title="Днів для відстрочки"
										start={10}
										end={16}
										icon={DelayIcon}
										prefix=" days"
										precision={0}
										className={styles.progressBadge}
										subTitle={
											<span style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '286px', width: '100%' }}>
												<span>{formatPlural(client?.daysOfDelay)}</span>
											</span>
										}
									/>

									{/* !!! TEMP disabled */}
									{/* <div className={styles.controlsContainer}>
											<Button text="Несплачені рахунки" variant="bordered" onClick={null} className={styles.unpaidButton} />
											<Button text="Надіслати нагадування" variant="default" onClick={null} className={styles.sendReminder} />
										</div> */}
								</div>
							</ClientProfileDetails>
						</div>
					</MediaQuery>

					<Spacer height={spacerSize} />

					<div className={styles.contentLayoutContainer}>
						<div className={styles.progressTiles}>
							<PaymentProgressTile
								getProgressColor={getProgressColor}
								onClick={handlePaymentInfoClick(CLIENT_PAGE_FILTER_KEYS.waiting)}
								title="Рахунки, що очікують оплати"
								from={client?.waitingForPayment?.amount}
								to={client?.waitingForPayment?.fromAmount}
								amount={client?.waitingForPayment?.sum}
							/>

							<PaymentProgressTile
								getProgressColor={getProgressColor}
								onClick={handlePaymentInfoClick(CLIENT_PAGE_FILTER_KEYS.overdue)}
								title="Протерміновані рахунки"
								from={client?.overduePayments?.amount}
								to={client?.overduePayments?.fromAmount}
								amount={client?.overduePayments?.sum}
							/>

							<PaymentProgressTile
								getProgressColor={getProgressColor}
								onClick={handlePaymentInfoClick(CLIENT_PAGE_FILTER_KEYS.prepaid)}
								title="Передплачені рахунки"
								from={client?.prepaidPayments?.amount}
								to={client?.prepaidPayments?.fromAmount}
								amount={client?.prepaidPayments?.sum}
							/>
						</div>

						<div className={styles.contentLayout}>
							<ClientContacts clientType={type} />

							<MediaQuery minWidth={breakPoints.MOBILE}>
								<div className={styles.clientStateBadge} style={{ backgroundColor: clientStateBadgeColor }} />

								<div className={clsx(styles.rightColumn, 'hide-scroll-bar')}>
									<ClientProfileDetails
										skipResponsive
										title="Загальна сума закупок"
										totalAmount={client?.totalSum}
										since={client?.firstRealization}
										subtitle=""
									>
										<div className={styles.badgeContainer}>
											<Suspense fallback={<div>Loader...</div>}>
												<FileUploadField disabled className={styles.fileUploadField} />
											</Suspense>

											<ProgressBadge
												forceDisplayProgressCompleteIcon
												readonly
												coloredProgressBar
												title={`Доступно ${formatNumberToUAH(client?.available?.availableSum)}`}
												subTitle={`Борг - ${formatNumberToUAH(client?.available?.debt)} - Ліміт ${formatNumberToUAH(
													client?.available?.limit,
												)}`}
												start={client?.available?.debt}
												end={client?.available?.limit}
												icon={UploadIcon}
												precision={0}
												className={styles.progressBadge}
											/>

											<ProgressBadge
												readonly
												excludeProgressBar
												title="Днів для відстрочки"
												start={10}
												end={16}
												icon={DelayIcon}
												prefix=" days"
												precision={0}
												className={styles.progressBadge}
												subTitle={
													<span
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															maxWidth: '286px',
															width: '100%',
														}}
													>
														<span>{formatPlural(client?.daysOfDelay)}</span>
													</span>
												}
											/>

											{/* !!! TEMP disabled */}
											{/* <div className={styles.controlsContainer}>
													<Button
														text="Несплачені рахунки"
														variant="bordered"
														onClick={null}
														className={styles.unpaidButton}
													/>
													<Button
														text="Надіслати нагадування"
														variant="default"
														onClick={null}
														className={styles.sendReminder}
													/>
												</div> */}
										</div>
									</ClientProfileDetails>
								</div>
							</MediaQuery>
						</div>
					</div>
				</div>
			</div>
		</FormProvider>
	);
};

export default ClientCard;
