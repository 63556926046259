import { OptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import { z } from 'zod';

import { BaseEmployee, EmployeeOrganization, OrderCreatorEmployee } from './employee';

export const ServerSideEmployeeOptionSchema = OrderCreatorEmployee.merge(BaseEmployee);

export const EmployeeOptionSchema = standardizeOption<typeof OptionSchema, ServerSideEmployeeOption>(OptionSchema, (manager) => ({
	label: manager.name,
	value: manager['1c_uuid'] ?? '',
}));
export const OrganizationOptionSchema = standardizeOption<typeof OptionSchema, EmployeeOrganization>(OptionSchema, ({ id, title }) => {
	if (!title) return null;

	return {
		label: title,
		value: String(id),
	};
});

export type ServerSideEmployeeOption = z.infer<typeof ServerSideEmployeeOptionSchema>;
export type ServerSideOrganizationOption = z.infer<typeof ServerSideEmployeeOptionSchema>;
export type OrganizationOption = z.infer<typeof OrganizationOptionSchema>;
