import clsx from 'clsx';
import Clamp from 'components/Clamp';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import Input from 'components/Input';
import { Option } from 'models/common/options';
import React, { useState } from 'react';

import sharedStyles from '../../style.module.css';
import styles from './styles.module.css';

interface SearchableFilterProps {
	placeholder?: string;
	options: Option[];
	className?: string;
	disabled?: boolean;
	value: string[];
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchableFilter = ({ options, placeholder, disabled, value, onChange }: SearchableFilterProps) => {
	const [search, setSearch] = useState<string>('');

	const resolvedOptions =
		search.trim() === '' ? options : options.filter((option) => option.label.toLowerCase().includes(search.trim().toLowerCase()));

	return (
		<>
			<Input type="text" placeholder={placeholder} setValue={setSearch} value={search} className={clsx({ [styles.disabled]: disabled })} />

			{resolvedOptions.map((option) => {
				return (
					<span key={option.value} className={clsx(sharedStyles.checkboxWithP, { [styles.disabled]: disabled })}>
						<Checkbox onChange={onChange} value={option.value} checked={value?.includes(option.value)} />
						<Clamp as="span" lines={1}>
							{option.label}
						</Clamp>
					</span>
				);
			})}
		</>
	);
};

export default SearchableFilter;
