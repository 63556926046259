import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

const root = (state: RootState) => state;

const selectWhoamI = createDraftSafeSelector(root, (state) => state.auth.user);

export const authSelectors = {
	selectWhoamI,
};
