/* eslint-disable react/prop-types */
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useSearchParamsController } from '..';
import type { MultipleStringController as MultipleStringControllerType } from '../types';

export type MultipleStringControllerProps = MultipleStringControllerType & {
	render: (props: {
		value: string[];
		onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
		hasActiveFilter?: boolean;
		activeFiltersCount?: number;
		indeterminateFiltersCount?: number;
		resetKey: (keyHash: string) => void;
		getValue: (keyHash: string) => AnyArg;
		setValue: (keyHash: string, value: AnyArg) => AnyArg;
		keyHash: string;
		queryKey: string;
	}) => React.ReactNode;
};

const MultipleStringController: React.FC<MultipleStringControllerProps> = ({ queryKey, render }) => {
	const [key, setKey] = useState(null);
	const [searchParams] = useSearchParams();
	const store = useSearchParamsController();

	const activeFiltersCount = store.getCountSingleAppliedQueryKey(key);

	useLayoutEffect(() => {
		const urlValue = searchParams.getAll(queryKey);
		const keyHash = store.registerKey(queryKey, urlValue?.filter(Boolean) ?? [], { hashStrategy: 'multiple' });
		setKey(keyHash);
	}, []);

	const renderer = useCallback(
		({ field }: { field: ControllerRenderProps }) => {
			const indeterminateFiltersCount = store.getCountSingleIndeterminateQueryKey(key);

			const el = render({
				value: field.value,
				onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					const value = event.target.value;

					if (Array.isArray(field.value)) {
						if (field.value.includes(value)) {
							field.onChange(field.value.filter((val: string) => val !== value));
						} else {
							field.onChange([...field.value, value]);
						}
					}
				},
				activeFiltersCount,
				hasActiveFilter: activeFiltersCount > 0,
				indeterminateFiltersCount,
				resetKey: store.resetKey,
				getValue: store.getValue,
				setValue: store.setValue,
				keyHash: key,
				queryKey,
			});

			return <>{el}</>;
		},
		[render, activeFiltersCount, store.resetKey, store.getValue, key, queryKey],
	);

	if (!key) return null;

	return <Controller name={key} render={renderer} />;
};

MultipleStringController.displayName = 'string-multiple';

export default MultipleStringController;
