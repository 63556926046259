import ColorfulSelect from 'components/ColorfulSelect';
import { breakPoints } from 'const';
import { MetalCuttingSubService } from 'models/service';
import { getEntityDIYPrice } from 'pages/OrderRework/OrderController/lib/utils';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MediaQuery from 'react-responsive';

import RootButton from './RootButton';
import styles from './styles.module.css';
import { useBackdrop } from './useBackdrop';

interface ServiceOptionSelectDependantFieldProps {
	entityId: string;
	suborderIndex: number;
	placeholder?: string;
	disabled?: boolean;
}

const ServiceOptionSelectDependantField = ({ placeholder, entityId, suborderIndex, disabled }: ServiceOptionSelectDependantFieldProps) => {
	const { control, watch, setValue, getValues } = useFormContext();
	const dependantFieldName = `suborders.${suborderIndex}.data.services.${entityId}.category` as const;
	const priceField = `suborders.${suborderIndex}.data.services.${entityId}.price` as const;
	const codeField = `suborders.${suborderIndex}.data.services.${entityId}.code` as const;

	const service = watch<AnyArg>(`suborders.${suborderIndex}.data.services.${entityId}.service`);
	const options = service?.services ?? [];

	const { ref } = useBackdrop();

	const getInitialValue = (value: AnyArg) => {
		const subEntityService = options?.find((s) => s?.id === value?.id) as MetalCuttingSubService;
		const source = (subEntityService || options?.[0]) ?? {};
		return {
			id: source?.id ?? '',
			title: source?.title ?? '',
			code: source.code ?? '',
			prices: source?.prices ?? [],
		};
	};

	const hasValue = getValues(dependantFieldName);

	useEffect(() => {
		if (options.length > 0 && !hasValue) {
			const defaultValue = getInitialValue(options[0]);
			const defaultPrice = getEntityDIYPrice(defaultValue, 'services');
			const defaultCode = defaultValue.code;
			setValue(dependantFieldName, defaultValue);
			setValue(priceField, defaultPrice);
			setValue(codeField, defaultCode);
		}
	}, [options, dependantFieldName, setValue, hasValue]);

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<Controller
					name={dependantFieldName}
					control={control}
					render={({ field }) => {
						return (
							<ColorfulSelect
								closeOnSelect
								popupHeaderTitle="Виберіть за параметрами"
								onSelect={(newValue) => {
									field.onChange(newValue);
									setValue(priceField, getEntityDIYPrice(newValue as AnyArg, 'services'));
								}}
								popupHeaderClassName={styles.popupHeader}
								value={field.value}
								placeholder={placeholder}
								// @ts-ignore
								rootButton={(props) => <RootButton {...props} disabled={disabled} title={placeholder} />}
								options={options ?? []}
							/>
						);
					}}
				/>
			</MediaQuery>
			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectDependantField;
