import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import React from 'react';
import { ReactComponent as TrashIcon } from 'static/images/trash-02.svg';

const message = 'Всі незбережені зміни та незбережені підзаявки будуть втрачені. Зміни доведеться вносити знову.';
const headerText = 'Ви впевнені, що хочете вийти з розбиття?';

interface IProps {
	onSubmit: VoidCallback;
	onCancel: VoidCallback;
	onClose: VoidCallback;
}

const CancelSpittingAlertDialogue = ({ onClose, onSubmit, onCancel }: IProps) => {
	const handleSubmit = (e) => {
		e.stopPropagation();
		onSubmit();
		onClose();
	};

	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>{headerText}</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage>{message}</AlertDialogueMessage>
			</AlertDialogueContent>

			<AlertDialogueFooter>
				<AlertDialogueControlButton variant="cancel" onClick={onCancel}>
					Скасувати
				</AlertDialogueControlButton>

				<AlertDialogueControlButton variant="submit" onClick={handleSubmit}>
					<TrashIcon />
					<span>Все одно вийти</span>
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
};

export default CancelSpittingAlertDialogue;
