import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import React from 'react';
import { ReactComponent as ChevronRightIcon } from 'static/images/chevron-right.svg';

interface Props {
	onSubmit: () => void;
	onCancel: () => void;
	onClose: () => void;
	data: number;
}

const CancelSuborderReservationAlertDialogue = ({ data, onClose, onCancel, onSubmit }: Props) => {
	const headerText = `Зняття з резервації Заявка ${data}?`;

	const handleSubmit = () => {
		onSubmit();
		onClose();
	};

	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>{headerText}</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage>{'Статус "Знято з резерва" буде додано лише для поточної підзаявки'}</AlertDialogueMessage>
				<AlertDialogueMessage>Бажаєте прозовжити?</AlertDialogueMessage>
			</AlertDialogueContent>

			<AlertDialogueFooter>
				<AlertDialogueControlButton variant="cancel" onClick={onCancel}>
					Скасувати
				</AlertDialogueControlButton>

				<AlertDialogueControlButton variant="submit" onClick={handleSubmit}>
					<ChevronRightIcon style={{ transform: 'rotate(-180deg)' }} />
					<span>Зняти з резерва</span>
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
};

export default CancelSuborderReservationAlertDialogue;
