import Checkbox from 'components/ColorfulSelect/Checkbox';
import type { MultipleStringControllerProps } from 'components/SearchParamsController/MultipleStringController';
import MultipleStringController from 'components/SearchParamsController/MultipleStringController';
import SingleStringController from 'components/SearchParamsController/SingleStringController';
import { PRE_FILTER_CLIENT_ROLES } from 'const';
import { useAuth } from 'hooks/useAuth';
import type { Option } from 'models/common/options';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as UserCircle } from 'static/images/user-circle.svg';

import SearchableFilter from '../components/SearchableFilter';
import SingleFilterWrapper from '../components/SingleFilterWrapper';
import styles from '../style.module.css';

type ByResponsibleFilterProps = Pick<MultipleStringControllerProps, 'queryKey'> & {
	title?: string;
	icon?: React.ReactElement;
	placeholder?: string;
	queryTip?: string;
	options: Partial<Option>[];
};

const ByResponsibleFilter: React.FC<ByResponsibleFilterProps> = ({
	queryKey,
	title = 'Відп. особа',
	icon = <UserCircle />,
	placeholder = 'Введіть імʼя менеджера',
	queryTip = 'Шукати за прізвищем',
	options,
}) => {
	const { user } = useAuth();
	const [searchParams] = useSearchParams();
	const isExpanded = searchParams.has(queryKey);
	const needPreFilterClients = PRE_FILTER_CLIENT_ROLES.includes(user?.role);
	let mineQueryHash = '';

	return (
		<MultipleStringController
			queryKey={queryKey}
			render={({
				value,
				onChange,
				getValue,
				setValue,
				resetKey,
				activeFiltersCount,
				indeterminateFiltersCount,
				keyHash: responsibleKeyHash,
			}) => {
				const handleResponsibleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
					const mineValue = getValue(mineQueryHash);

					if (mineValue) {
						setValue(mineQueryHash, '');
					}

					onChange(e);
				};

				return (
					<SingleFilterWrapper
						activeFiltersCount={activeFiltersCount}
						indeterminateFiltersCount={indeterminateFiltersCount}
						expanded={isExpanded}
						title={title}
						{...(!!icon && { icon })}
					>
						<span className={styles.subTitle}>{queryTip}</span>

						{needPreFilterClients && (
							<SingleStringController
								queryKey="mine"
								render={(field) => {
									if (!mineQueryHash) {
										mineQueryHash = field.keyHash;
									}

									return (
										<div className={styles.mineCheckboxWrapper}>
											<Checkbox
												label="Лише мої менеджери"
												checked={!!field.value}
												onChange={() => {
													if (value.length > 0 && !field.value) {
														resetKey(responsibleKeyHash);
													}

													field.onChange(!field.value ? user?.['1c_uuid'] : '');
												}}
											/>
										</div>
									);
								}}
							/>
						)}

						<div className={styles.checkboxGroupWrapper}>
							<SearchableFilter
								value={value}
								onChange={handleResponsibleChange}
								placeholder={placeholder}
								options={options as Required<Option>[]}
							/>
						</div>
					</SingleFilterWrapper>
				);
			}}
		/>
	);
};

ByResponsibleFilter.displayName = MultipleStringController.displayName;

export default ByResponsibleFilter;
