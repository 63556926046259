import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { replaceComaWithDot } from 'utils/inputs';
import { toFixed } from 'utils/shared';

interface OrderProductPriceInputProps {
	suborderIndex: number;
	entityId: string;
}

const OrderProductPriceInput: React.FC<OrderProductPriceInputProps> = ({ suborderIndex, entityId }) => {
	const { control, setValue, getValues } = useFormContext();
	const priceFieldName = `suborders.${suborderIndex}.data.products.${entityId}.price` as const;

	const updateValues = (price: number) => {
		const allSuborders = getValues('suborders') ?? [];

		const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
			if (index === 0) return acc;
			if (index !== suborderIndex) {
				return (acc += Number(suborder.data?.products?.[entityId]?.amount || 0) * Number(suborder.data?.products?.[entityId]?.price || 0));
			}

			acc += Number(price) * Number(suborder.data?.products?.[entityId]?.amount || 0);
			return acc;
		}, 0);

		const amount = getValues(`suborders.${suborderIndex}.data.products.${entityId}.amount`);

		setValue(`suborders.${0}.data.products.${entityId}.sum`, toFixed(sumInAllEntityOccurrences));
		setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, toFixed(price * Number(amount)));
		allSuborders.forEach((_, index) => {
			setValue(`suborders.${index}.data.products.${entityId}.price`, toFixed(price));
		});
	};

	return (
		<Controller
			name={priceFieldName}
			control={control}
			render={({ field }) => {
				return (
					<TableCellInput
						type="text"
						value={field.value}
						onKeyDown={replaceComaWithDot}
						onChange={(e) => {
							const price = Number(e.currentTarget.value);
							updateValues(price);
							field.onChange(e);
						}}
						onBlur={() => {
							const numberedValue = Number(field.value);

							if (!numberedValue) {
								updateValues(0);
								field.onChange('0.00');
							} else {
								field.onChange(toFixed(numberedValue, { precise: 2 }));
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default OrderProductPriceInput;
