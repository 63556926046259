import clsx from 'clsx';
import React from 'react';
import { formatAndExtractDateTime } from 'utils/dates';
import { formatNumberWithSpaces } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps } from './types';

const ClientProfileDetails: React.FC<IProps> = ({
	children,
	type = 'Кредитна лінія',
	totalAmount,
	skipResponsive = false,
	title,
	since,
	numberFormatter = formatNumberWithSpaces,
}) => {
	const formatted = numberFormatter(totalAmount ?? 0);
	let date = '—';

	if (since) {
		const [formattedDate] = formatAndExtractDateTime(since);
		date = formattedDate;
	}

	return (
		<div data-skip-responsive={skipResponsive} className={styles.historyWrapper}>
			<div className={styles.header}>
				<p className={styles.title}>{title}</p>
				{!!since && (
					<p className={clsx(styles.subTitle, 'text-sm-regular')}>
						з <time dateTime={date}>{date}</time>
					</p>
				)}

				{totalAmount !== undefined && <strong className={styles.amount}>{formatted} ₴</strong>}
				<p className={styles.purchaseType}>{type}</p>
			</div>

			{children}
		</div>
	);
};

export default ClientProfileDetails;
