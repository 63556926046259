import Counter from 'components/Counter';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { formatInputValue } from 'utils/inputs';
import { toFixed } from 'utils/shared';

import styles from './styles.module.css';
import type { ServiceCounterProps } from './types';

const ServiceCounter: React.FC<ServiceCounterProps> = ({ suborderIndex, entityId }) => {
	const { control, setValue, getValues } = useFormContext();

	const updateValues = (amount: number) => {
		const allSuborders = getValues('suborders') ?? [];
		const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
			if (index === 0) return acc;
			if (index !== suborderIndex) {
				return (acc += Number(suborder.data.services[entityId]?.amount) * Number(suborder.data.services[entityId]?.price));
			}
			acc += Number(amount) * Number(suborder.data.services[entityId]?.price);
			return acc;
		}, 0);

		setValue(`suborders.${0}.data.services.${entityId}.sum`, toFixed(sumInAllEntityOccurrences));
		setValue(`suborders.${0}.data.services.${entityId}.amount`, toFixed(amount));
	};

	const valueFormatter = formatInputValue({ isInt: true });

	return (
		<Controller
			name={`suborders.${suborderIndex}.data.services.${entityId}.amount`}
			control={control}
			render={({ field }) => {
				const numberedValue = Number(field.value);

				return (
					<Counter
						min={1}
						value={isNaN(numberedValue) ? 1 : numberedValue}
						onQuantityChange={(newValue) => {
							const val = valueFormatter(newValue);
							// @ts-ignore
							updateValues(val);
							field.onChange(val);
						}}
						onBlur={() => {
							if (!numberedValue) {
								updateValues(0);
								field.onChange('0');
							} else {
								field.onChange(valueFormatter(numberedValue));
							}
						}}
						className={styles.servicesCounter}
					/>
				);
			}}
		/>
	);
};

export default ServiceCounter;
