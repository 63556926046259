declare global {
	interface Navigator {
		msMaxTouchPoints?: number;
	}
}

export function hasTouchScreen() {
	return (
		'ontouchstart' in window || navigator?.maxTouchPoints > 0 || navigator?.msMaxTouchPoints > 0 || !window.matchMedia('(hover: hover)').matches
	);
}

export function supportsCapture() {
	const inputEl = document.createElement('input');
	const supports = inputEl.capture != undefined;

	return supports;
}
