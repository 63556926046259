import { AxiosError } from 'axios';

export const throwAnException = (err: unknown) => {
	if (err instanceof AxiosError) {
		let msg = err.response?.data?.message;

		if (err.code === 'ERR_NETWORK') {
			msg = 'Віддалений сервер недоступний';
		}

		if (err?.response?.data && 'errors' in err?.response?.data) {
			msg = Object.entries(err?.response?.data.errors)
				.map(([, reason]) => reason)
				.join('\n');
		}

		throw new Error(msg);
	}

	throw err;
};
