import clsx from 'clsx';
import { CanUser } from 'contexts/AbilityContext';
import { BaseClientFormValues } from 'models/IClient';
import { IndividualClientFormField, LegalClientFormField } from 'models/IClientFormFields';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { checkIfFieldIsBlacklistedForEdit, checkIfHasSpecialization } from 'utils/forms';

import FieldGroup from './FieldGroup';
import { individualClientFormFields, legalClientFormFields, specializationField } from './Inputs/clientFormInputs';
import styles from './styles.module.css';
import type { IProps } from './types';

const ClientFormEdit: React.FC<IProps> = ({ className, clientType, onSubmit, ...restProps }) => {
	const fields = clientType === 'individual' ? individualClientFormFields : legalClientFormFields;

	const { getValues } = useFormContext();

	const { segment } = getValues();
	const hasSpecialization = checkIfHasSpecialization(segment?.title);

	return (
		<form id="edit-client-form" className={clsx(styles.clientForm, className)} onSubmit={onSubmit} {...restProps}>
			{fields.map((fieldGroup: IndividualClientFormField[] | LegalClientFormField[], index: number) => (
				<FieldGroup key={index}>
					{fieldGroup.map((field: IndividualClientFormField | LegalClientFormField, position: number, arr: unknown[]) => {
						const { Component, name, id, ability = [], createPlaceholder, placeholder, ...props } = field;
						const isEditProhibited = checkIfFieldIsBlacklistedForEdit(name as keyof BaseClientFormValues);
						const [abilityAction, abilityEntity] = ability;

						if (hasSpecialization && index === 0 && position === arr.length - 1) {
							const { Component: SpecializationField, id: specializationId, ...specializationFieldProps } = specializationField;

							if (!abilityAction && !abilityEntity) {
								return (
									<React.Fragment key={id + specializationId}>
										<Component
											{...props}
											name={name}
											disabled={isEditProhibited}
											placeholder={placeholder ?? createPlaceholder}
										/>
										<SpecializationField {...specializationFieldProps} disabled={isEditProhibited} />
									</React.Fragment>
								);
							}

							return (
								<CanUser key={id} do={abilityAction} an={abilityEntity}>
									<Component {...props} name={name} disabled={isEditProhibited} />
									<SpecializationField key={specializationId} {...specializationFieldProps} disabled={isEditProhibited} />
								</CanUser>
							);
						}

						if (!abilityAction && !abilityEntity) {
							return (
								<Component
									key={id}
									name={name}
									{...props}
									disabled={isEditProhibited}
									placeholder={placeholder ?? createPlaceholder}
								/>
							);
						}

						return (
							<CanUser key={id} do={abilityAction} an={abilityEntity}>
								<Component name={name} {...props} disabled={isEditProhibited} placeholder={placeholder ?? createPlaceholder} />
							</CanUser>
						);
					})}
				</FieldGroup>
			))}
		</form>
	);
};

export default ClientFormEdit;
