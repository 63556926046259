import { useBoolean } from 'hooks/useBoolean';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import cssStyles from './styles.module.css';

interface CommentsTooltipProps {
	children: React.ReactNode;
	text: React.ReactNode;
}

const CommentsTooltip: React.FC<CommentsTooltipProps> = ({ children, text }) => {
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top' });
	const visible = useBoolean();

	return (
		<span ref={setReferenceElement} onMouseEnter={visible.open} onMouseLeave={visible.close} className={cssStyles.wrapper}>
			{children}

			{visible.isOn &&
				createPortal(
					<div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="tooltip">
						{text}
					</div>,
					document.querySelector('#portal'),
				)}
		</span>
	);
};

export default CommentsTooltip;
