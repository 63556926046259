import React from 'react';
import { kFileSizeFormatter } from 'utils/shared';

import styles from './styles.module.css';
import type { AcceptedFileTypes, Dimension } from './types';

export function matchesMaxDimensions(file: Dimension, max: Dimension) {
	return file.width <= max.width && file.height <= max.height;
}

export function extractFileExtensions(files: AcceptedFileTypes) {
	const fileExtensions = Object.keys(files).map((fileType) => {
		const extension = fileType.split('/')[1];

		if (extension?.includes('+')) {
			return extension.split('+')[0];
		}

		return extension;
	});

	return fileExtensions;
}

export function createBadgeTextFromFileExtensions(arr: string[], joiner?: string) {
	if (arr.length === 0) {
		return null;
	}

	const firstPart = arr.slice(0, -1).join(', ');
	const lastPart = arr.slice(-1);

	if (firstPart.length === 1) {
		return <span>{firstPart.replace(',', '')}</span>;
	}

	const joined = (
		<span>
			{firstPart} <span className={styles.joiner}>{joiner ?? 'або'}</span>
			&nbsp;{lastPart}
		</span>
	);

	return joined;
}

export function createMaxDimensionsBadgeText(max: Dimension, prefix?: string, suffix?: string) {
	if (!max?.width || !max?.height) {
		return null;
	}

	const maxDimension = `(${prefix ?? 'макс.'} ${max.width}x${max.height}${suffix ?? 'px'})`;

	return <span className={styles.dimension}>&nbsp;{maxDimension}</span>;
}
export function createMaxFileSizeBadgeText(max?: number, prefix?: string) {
	if (!max) {
		return null;
	}

	const maxFileSize = `(${prefix ?? 'макс.'} ${kFileSizeFormatter(max, 0)})`;

	return <span className={styles.maxSize}>&nbsp;{maxFileSize}</span>;
}

export async function getImageDimensions(file: Blob) {
	const img = new Image();
	img.src = URL.createObjectURL(file);

	await img.decode();

	URL.revokeObjectURL(img.src);

	const width = img.width;
	const height = img.height;

	return {
		width,
		height,
	};
}
