import clsx from 'clsx';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE_IN_BYTES } from 'const';
import React, { ForwardedRef, forwardRef, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'static/images/circled-cloud.svg';
import { ReactComponent as UploadIconBlue } from 'static/images/download-cloud2.svg';
import { kFileSizeFormatter } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps, UploadFieldRef } from './types';
import { createBadgeTextFromFileExtensions, createMaxFileSizeBadgeText, extractFileExtensions } from './utils';

const FileUploadField = (
	{
		label = 'Підгрузити документ',
		accept = ACCEPTED_FILE_TYPES,
		maxFileSizeInBytes = MAX_FILE_SIZE_IN_BYTES,
		className,
		onFileUpload,
		onError,
		onBeforeDrop,
		multiple = true,
		documentsMode,
		disabled,
		error,
		...restProps
	}: IProps,
	ref: ForwardedRef<UploadFieldRef>,
) => {
	const extensions = extractFileExtensions(accept);
	const acceptedFileFormatsBadge = createBadgeTextFromFileExtensions(extensions);
	const maxFileSizeBadge = createMaxFileSizeBadgeText(maxFileSizeInBytes);

	const handleFileDrop = useCallback(
		async <T extends Blob>(acceptedFiles: T[]) => {
			onBeforeDrop?.();
			// const imageDimensions = await getImageDimensions(file);
			// const isInAllowedDimensionRange = matchesMaxDimensions(imageDimensions, maxDimension);

			// if (!isInAllowedDimensionRange) {
			// 	return onError?.('Розмір зображення не відповідає вимогам');
			// }

			const validFiles = acceptedFiles.filter((file) => file.size <= maxFileSizeInBytes);

			if (validFiles.length !== acceptedFiles.length) {
				return onError?.(
					`Один або декілька файлів перевищують максимальний розмір. \n Максимальний розмір - ${kFileSizeFormatter(maxFileSizeInBytes, 0)}`,
				);
			}

			const files = multiple ? (acceptedFiles as Blob[]) : (acceptedFiles[0] as Blob);
			onFileUpload?.(files);
		},
		[onFileUpload],
	);

	return (
		<Dropzone ref={ref} accept={accept} multiple={multiple} onDrop={handleFileDrop} onError={onError} disabled={disabled}>
			{({ getRootProps, getInputProps }) => (
				<section
					data-file-upload-field
					className={clsx(styles.dropAreaWrapper, className, {
						[styles.documentsBg]: documentsMode,
						[styles.disabled]: disabled,
						[styles.hasError]: !!error,
					})}
					{...restProps}
				>
					<div {...getRootProps()} className={styles.contentWrapper}>
						<input {...getInputProps()} />

						{documentsMode ? <UploadIconBlue className={styles.icon} /> : <UploadIcon className={styles.icon} />}

						<strong className={clsx(styles.acceptedFormats, 'text-sx-regular', 'color-grey-600 ')}>
							{acceptedFileFormatsBadge}
							{maxFileSizeBadge}
						</strong>

						<p className={clsx(styles.title, 'text-sm-bold', { 'color-primary-700': !error })}>{label}</p>

						{!!error && <strong className={clsx(styles.error)}>{error}</strong>}
					</div>
				</section>
			)}
		</Dropzone>
	);
};

export default forwardRef(FileUploadField);
