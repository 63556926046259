import clsx from 'clsx';
import Button from 'components/Button';
import React from 'react';

import styles from './styles.module.css';

interface MainSearchSwitchButtonProps {
	onClick?: VoidCallback;
	isActive?: boolean;
	title: string;
	queryKey: string;
}

export const MainSearchSwitchButton: React.FC<MainSearchSwitchButtonProps> = ({ onClick, title, isActive }) => {
	return (
		<Button
			text={title}
			onClick={onClick}
			variant="smallRounded"
			className={clsx(styles.switchUrlQueryButton, { [styles.inActive]: !isActive })}
		/>
	);
};
