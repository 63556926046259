import { isString } from 'utils/type-guards';

import { isExternallyControlledMultipleSelect, isExternallyControlledSelect, isInnerControlledMultipleSelect } from './guards';
import { useExternalControlledMultipleState } from './hooks/useExtrenalControlledMultipleState';
import { useExternalControlledState } from './hooks/useExtrenalControlledState';
import { useInnerControlledMultipleState } from './hooks/useInnerControlledMultippleState';
import { useInnerControlledState } from './hooks/useInnerControlledState';
import type {
	ExternalControlledMultipleSelectProps,
	ExternalControlledSelectProps,
	InnerControlledMultipleSelectProps,
	InnerControlledSelectProps,
	SelectOption,
} from './types';

export function getOptionLabel(option: string | SelectOption) {
	return (isString(option) ? option ?? '' : option?.label ?? '') ?? '';
}

export function hydrateActiveIndexState(
	options: SelectOption[] | string[],
	value: SelectOption | string | SelectOption[] | string[] | (SelectOption | string)[],
) {
	const hydrator = () => {
		const initialIndex = Array.isArray(value)
			? value?.findIndex((v) => options?.some((option) => getOptionLabel(option) === getOptionLabel(v)))
			: options?.findIndex((option) => getOptionLabel(option) === getOptionLabel(value));

		return initialIndex === -1 ? null : initialIndex;
	};

	return hydrator;
}

export const useStateController = (
	props: InnerControlledSelectProps | ExternalControlledSelectProps | InnerControlledMultipleSelectProps | ExternalControlledMultipleSelectProps,
) => {
	if (isInnerControlledMultipleSelect(props)) {
		return useInnerControlledMultipleState.call(null, props);
	}

	if (isExternallyControlledMultipleSelect(props)) {
		return useExternalControlledMultipleState.call(null, props);
	}

	if (isExternallyControlledSelect(props)) {
		return useExternalControlledState.call(null, props);
	}

	return useInnerControlledState.call(null, props);
};

export const matchesSelectedOption = (
	selectedOption: string | string[] | (Record<string, unknown> & SelectOption) | (Record<string, unknown> & SelectOption)[],
	candidateOption: string | (Record<string, unknown> & SelectOption),
) => {
	if (Array.isArray(selectedOption)) {
		return selectedOption.some((option) => getOptionLabel(option) === getOptionLabel(candidateOption));
	}

	return getOptionLabel(selectedOption) === getOptionLabel(candidateOption);
};
