import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import { IProps } from './types';

const FieldGroup: React.FC<IProps> = ({ children, className, ...props }) => {
	return (
		<fieldset data-form-field-group className={clsx(styles.fieldGroup, className)} {...props}>
			{children}
		</fieldset>
	);
};

export default FieldGroup;
