import Clamp from 'components/Clamp';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import Input from 'components/Input';
import SpinnerV2 from 'components/Spinner-v2';
import VirtualizedList from 'components/VirtualizedList';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import React, { useCallback, useState } from 'react';
import { useGetClientsOptionsQuery } from 'store/reducers/clients/clientsSliceApi';
import { getFilterItemSize, getInputUniqueId, getVirtualListHeight } from 'utils/shared';

import sharedStyles from '../../style.module.css';
import styles from './styles.module.css';

interface SearchableServerSideFilterProps {
	placeholder?: string;
	className?: string;
	value: string[];
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchableServerSideFilter: React.FC<SearchableServerSideFilterProps> = ({ value, onChange, placeholder }) => {
	const [search, setSearch] = useState<string>('');
	const [query, setQuery] = useState<string>(search);
	const { data = [], isLoading, isFetching } = useGetClientsOptionsQuery(query);

	const onQueryChange = useCallback((newValue: string) => {
		setQuery(newValue);
	}, []);
	const onChangeDebounced = useDebouncedCallback(onQueryChange, 300);

	const onSearchChange = useCallback((newValue: string) => {
		setSearch(newValue);
		onChangeDebounced(newValue);
	}, []);

	const resolvedOptions = data;

	if (isLoading) {
		return (
			<div className={styles.loading}>
				<SpinnerV2 />
			</div>
		);
	}

	return (
		<div className={styles.wrapper}>
			<Input type="text" placeholder={placeholder} setValue={onSearchChange} value={search} className={styles.input} />

			<VirtualizedList
				items={resolvedOptions}
				itemSize={getFilterItemSize()}
				scrollOverscan={10}
				height={getVirtualListHeight(resolvedOptions.length)}
				width={383}
				renderItem={({ item, index, style }) => {
					const displayValue = item.label;

					return (
						<span key={item.value} className={sharedStyles.checkboxWithP} style={style}>
							<Checkbox
								hideLabel
								label={displayValue}
								name={getInputUniqueId([displayValue, index])}
								onChange={onChange}
								value={item.value}
								checked={value.includes(item.value)}
							/>
							<Clamp as="span" lines={1}>
								{displayValue}
							</Clamp>
						</span>
					);
				}}
			/>

			{isFetching && (
				<div className={styles.fetching}>
					<SpinnerV2 />
				</div>
			)}
		</div>
	);
};

export default SearchableServerSideFilter;
