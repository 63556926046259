import clsx from 'clsx';
import Button from 'components/Button';
// import { PaymentDateFilterPanel } from 'components/ClientProfilePage';
import ProgressBar from 'components/ProgressBar';
import React from 'react';
import { calculateProgress, formatNumberToUAH, formatNumberWithSpaces, getProgressBarFillColor } from 'utils/shared';

import styles from './style.module.css';
import type { IProps } from './types';

const PaymentProgressTile: React.FC<IProps> = ({
	title,
	from,
	to,
	amount,
	onClick,
	getProgressColor = getProgressBarFillColor,
	numberFormatter = formatNumberWithSpaces,
	priceFormatter = formatNumberToUAH,
}) => {
	const progress = calculateProgress(from, to);
	const fillColor = getProgressColor(progress);

	const formattedAmount = priceFormatter(amount);
	const formattedTo = numberFormatter(to);
	const formattedFrom = numberFormatter(from);

	return (
		<div className={styles.wrapper}>
			<div className={styles.titleWrapper}>
				<p className={clsx('text-sx-medium', 'color-grey-600')}>{title}</p>

				{/* <PaymentDateFilterPanel urlQueryKey={urlQueryKey} /> */}
			</div>
			<div className={styles.priceWrapper}>
				<p className={styles.from}>
					{formattedFrom}
					<span className={styles.to}> / {formattedTo}</span>
				</p>
				<p className={styles.from}>
					<span className="text-sx-medium">Всього:</span> {formattedAmount}
				</p>
			</div>
			<div className={styles.progressWrapper}>
				<ProgressBar progress={progress} fill={fillColor} />

				<div className={styles.leftBottomSlot}>
					<Button variant="inverseBordered" text="Переглянути" onClick={onClick} />
				</div>
			</div>
		</div>
	);
};

export default PaymentProgressTile;
