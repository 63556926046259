import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NavLink: React.FC<LinkProps> = ({ onClick, ...props }) => {
	const handleOnClick = useStopPropagationCallback(onClick);

	return <Link {...props} onClick={handleOnClick} />;
};

export default NavLink;
