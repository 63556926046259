import { useFieldArray, useFormContext } from 'react-hook-form';

import type { IProps } from './types';

const FieldArray = <TProps extends { name: string }>({ name, children }: IProps<TProps>) => {
	const { control } = useFormContext();
	const fieldArray = useFieldArray({ name, control });

	return children(fieldArray);
};

export default FieldArray;
