import { useSearchParamsController } from 'components/SearchParamsController';
import React, { useCallback } from 'react';

interface SearchParamsControllerAdapterRenderProps {
	onFiltersApply: VoidCallback;
	onFiltersReset: VoidCallback;
	hasAppliedFilters: boolean;
	isFilterApplyDisabled: boolean;
	generateQueryTemplateString: () => string;
	onTemplateApply: (template: string) => void;
}
interface SearchParamsControllerAdapterProps {
	render: (props: SearchParamsControllerAdapterRenderProps) => React.ReactNode;
}

const SearchParamsControllerAdapter: React.FC<SearchParamsControllerAdapterProps> = ({ render }) => {
	const { apply, clearAll, getCountAllAppliedQueryKeys, generateQueryTemplateString, isDirty, applyTemplate } = useSearchParamsController();

	const allAppliedFiltersCount = getCountAllAppliedQueryKeys();
	const hasAppliedFilters = allAppliedFiltersCount > 0;
	const isFilterApplyDisabled = !isDirty;

	const renderer = useCallback(render, [render]);

	return renderer({
		onFiltersApply: apply,
		onFiltersReset: clearAll,
		hasAppliedFilters,
		isFilterApplyDisabled,
		generateQueryTemplateString,
		onTemplateApply: applyTemplate,
	});
};

export default SearchParamsControllerAdapter;
