import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { formatInputValue, replaceComaWithDot } from 'utils/inputs';
import { roundNumber, toFixed } from 'utils/shared';

interface OrderProductAmountInputProps {
	suborderIndex: number;
	entityId: string;
	isInt: boolean;
}

const OrderProductAmountInput: React.FC<OrderProductAmountInputProps> = ({ suborderIndex, entityId, isInt }) => {
	const { control, setValue, getValues } = useFormContext();
	const amountFieldName = `suborders.${suborderIndex}.data.products.${entityId}.amount` as const;

	const updateValues = (amount: number) => {
		const allSuborders = getValues('suborders');
		const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
			if (index === 0) return acc;

			if (index !== suborderIndex) {
				return (acc += Number(suborder.data.products[entityId]?.amount || 0) * Number(suborder.data.products[entityId]?.price || 0));
			}

			acc += Number(amount) * Number(suborder.data.products[entityId]?.price || 0);
			return acc;
		}, 0);

		const amountOfAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
			if (index === 0) return acc;

			if (index !== suborderIndex) {
				return (acc += Number(suborder.data.products[entityId]?.amount || 0));
			}

			acc += Number(amount);
			return acc;
		}, 0);

		const price = Number(getValues(`suborders.${suborderIndex}.data.products.${entityId}.price`));
		setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, toFixed(roundNumber(price * amount, 2)));
		setValue(`suborders.${0}.data.products.${entityId}.sum`, toFixed(roundNumber(sumInAllEntityOccurrences, 2)));
		setValue(`suborders.${0}.data.products.${entityId}.amount`, toFixed(amountOfAllEntityOccurrences, { isInt }));
	};

	const formatValue = formatInputValue({ isInt });

	return (
		<Controller
			name={amountFieldName}
			control={control}
			render={({ field }) => {
				return (
					<TableCellInput
						type="text"
						value={field.value}
						{...(!isInt && { onKeyDown: replaceComaWithDot })}
						onChange={(e) => {
							const amount = Number(formatValue(e.currentTarget.value));

							if (suborderIndex > 0) {
								updateValues(amount);
							} else {
								const price = Number(getValues(`suborders.${suborderIndex}.data.products.${entityId}.price`));
								setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, toFixed(roundNumber(price * amount, 2)));
							}
							field.onChange(formatValue(e.currentTarget.value));
						}}
						onBlur={() => {
							const numberedValue = Number(field.value);

							if (!numberedValue) {
								const amount = 1;

								if (suborderIndex > 0) {
									updateValues(amount);
								} else {
									const price = Number(getValues(`suborders.${suborderIndex}.data.products.${entityId}.price`));
									setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, toFixed(roundNumber(price * amount, 2)));
								}

								field.onChange(isInt ? '1' : '1.00');
							} else {
								field.onChange(toFixed(formatValue(numberedValue), { isInt, precise: 2, strictPrecision: true }));
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default OrderProductAmountInput;
