import React from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const ProgressBar: React.FC<IProps> = ({ progress, fill, prefix = '%' }) => {
	return (
		<div className={styles.progressWrapper}>
			<div className={styles.backgroundProgress} role="progressbar">
				<div
					data-progress-bar-indicator
					className={styles.progressBar}
					style={{
						backgroundColor: fill,
						width: `${progress}%`,
					}}
				/>
			</div>
			<span className={styles.from}>
				{progress}
				{prefix}
			</span>
		</div>
	);
};

export default ProgressBar;
