import { CreatingModeProvider } from 'contexts/CreatingModeProvider';
import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import ToasterProvider from 'contexts/ToasterProvider';
import PageLayoutSkeleton from 'layouts/PageLayout/PageLayoutSkeleton';
import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';

const App: React.FC = () => {
	return (
		<Suspense fallback={<PageLayoutSkeleton />}>
			<CreatingModeProvider>
				<OrderAlertDialoguesProvider>
					<ToasterProvider>
						<RouterProvider router={router} />
					</ToasterProvider>
				</OrderAlertDialoguesProvider>
			</CreatingModeProvider>
		</Suspense>
	);
};

export default App;
