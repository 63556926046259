import clsx from 'clsx';
import Spinner from 'components/Spinner';
import TableCheckbox from 'components/Table/TableComponents/CheckboxColumn/TableCheckbox';
import { useModal } from 'hooks/useModal';
import { useOrderCart } from 'hooks/useOrderCart';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { lazy, Suspense } from 'react';
import { ReactComponent as SquaredCheckIcon } from 'static/images/check-square.svg';
import { ReactComponent as CircledPlusIcon } from 'static/images/plus-circle.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';
import { pluralizeProductCount } from 'utils/shared';

import styles from './styles.module.css';
import type { TableActionsPanelProps } from './types';

const BulkProductSelection = lazy(() => import('../BulkProductSelection'));
const ProductConfigurationToolModal = lazy(() => import('../ProductConfigurationToolModal'));

const TableActionsPanel: React.FC<TableActionsPanelProps> = ({
	allData,
	className,
	tableInstance: table,
	rowSelectionModel,
	onRowSelectionModelChange,
	addEntityToOrder,
	onReset,
	getValues,
}) => {
	const cart = useOrderCart();

	const onRowClick = (cb) => (rowIndex: number, quantity: number | string, id: string) => {
		if (rowIndex === 0) {
			cart.updateProductQuantityInCart(quantity);
		} else {
			cart.updateProductQuantityByIdInCart(String(id), quantity);

			cb?.();
		}
	};

	const onChangeEntityAmountInCart =
		(closeCb: VoidCallback) =>
		({ candidates }) => {
			const [product] = candidates;
			cart.updateProductQuantityByIdInCart(String(product.id), product.amount);
			closeCb?.();
		};

	const onEntityDelete = (id: string) => {
		const newSelectionModel = { ...rowSelectionModel };

		delete newSelectionModel[id];

		onRowSelectionModelChange(newSelectionModel);
	};

	const productConfigurationToolModal = useModal({
		modal: ({ closeModal }) => {
			return (
				<Suspense fallback={<Spinner />}>
					<ProductConfigurationToolModal
						stackable
						open
						mode="creation"
						addEntityToOrder={onChangeEntityAmountInCart(closeModal)}
						getValues={getValues}
						isLoading={cart.isLoading}
						onRowClick={onRowClick(closeModal)}
						onClose={closeModal}
					/>
				</Suspense>
			);
		},
		key: 'modal:bulk-action/product-preview',
	});

	const bulkSelectionModal = useModal({
		modal: ({ closeModal }) => {
			const handleSubmit = () => {
				table.resetRowSelection();
				closeModal();
			};
			const onCancel = () => {
				table.resetRowSelection();
				closeModal();
			};

			return (
				<Suspense fallback={<Spinner />}>
					<BulkProductSelection
						stackable
						onSubmit={handleSubmit}
						addEntityToOrder={addEntityToOrder}
						onClick={productConfigurationToolModal.openModal}
						onCancel={onCancel}
						onClose={closeModal}
						onDelete={onEntityDelete}
					/>
				</Suspense>
			);
		},
		key: 'modal:bulk-action/select-products',
	});
	const openModalToggle = useStopPropagationCallback(bulkSelectionModal.openModal);

	const handleCancel = () => {
		table.resetRowSelection();
		cart.clearMaybeSelectedAll();
		onReset?.();
	};
	const handleAllSelection = () => {
		cart.maybeSelectMany(allData);
		table.toggleAllRowsSelected();
	};

	// useEffect(() => {
	// 	const selectedProducts = table.getSelectedRowModel().rows.map((row) => row.original);

	// 	const cartProducts = cart.products.filter((cartProduct) => rowSelectionModel[cartProduct.id]);

	// 	console.log('selectedProducts -->', selectedProducts);
	// 	console.log('cartProducts -->', cartProducts);
	// 	selectedProducts.forEach((product) => {
	// 		const existingCartProduct = cartProducts.find((cartProduct) => cartProduct.id === product.id);

	// 		if (!existingCartProduct) {
	// 			cartProducts.push({ ...product, amount: product?.unit?.isInt ? '1' : '1.00' });
	// 		}
	// 	});

	// 	cart.maybeSelectMany(cartProducts);
	// }, [rowSelectionModel]);

	const selectedItemsCount = cart.products?.length ?? 0;

	if (Object.keys(rowSelectionModel).length === 0) return null;

	return (
		<div className={clsx(styles.wrapper, className)}>
			<div className={clsx(styles.tableActionsPanel)}>
				<TableCheckbox
					id="bulk-actions:toggle/all"
					indeterminate={table.getIsSomeRowsSelected()}
					checked={table.getIsAllRowsSelected()}
					onChange={table.getToggleAllRowsSelectedHandler()}
					className={styles.mainCheckbox}
				/>

				<div className={styles.actionButtonsContainer}>
					<button type="button" onClick={openModalToggle} className={styles.actionButton}>
						<CircledPlusIcon />
					</button>
				</div>

				<strong className={clsx(styles.itemsCount, 'text-sx-medium')}>
					<span>Вибрано</span>&nbsp;
					<span className={styles.itemsCountNumber}>{pluralizeProductCount(selectedItemsCount)}</span>
				</strong>

				<button
					type="button"
					onClick={handleAllSelection}
					className={clsx(styles.extraActionButton, styles.withState, styles.selectAll, 'text-sm-semibold')}
				>
					<SquaredCheckIcon />
					<span>Вибрати всі</span>
				</button>

				<button type="button" onClick={handleCancel} className={clsx(styles.extraActionButton, styles.cancel, 'text-sm-semibold')}>
					<XCloseIcon />
					<span>Скасувати</span>
				</button>
			</div>
		</div>
	);
};

export default TableActionsPanel;
