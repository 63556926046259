import clsx from 'clsx';
import IconButton from 'components/IconButton';
import React from 'react';
import Micro from 'static/images/micro.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

export const VoiceSearchButton: React.FC<IProps> = ({ className }) => {
	return (
		<IconButton
			className={clsx(styles.voiceSearch, className)}
			icon={Micro}
			width={'40px'}
			height={'40px'}
			background="var(--primary-50)"
			onClick={() => {}}
		/>
	);
};
