import clsx from 'clsx';
import React from 'react';
import { createInitialLettersFallback } from 'utils/shared';
import { isBoolean, isString } from 'utils/type-guards';

import styles from './styles.module.css';
import type { AvatarProps } from './types';

const Avatar: React.FC<AvatarProps> = ({ name, src, fallback, size, className, imgClassName, children }) => {
	const avatarFallback =
		!src &&
		(isString(fallback) ? (
			<img src={fallback} alt={name} />
		) : isBoolean(fallback) ? (
			<p className={clsx('text-sm-medium', styles.initials)}>{createInitialLettersFallback(name)}</p>
		) : (
			fallback
		));

	return (
		<div className={clsx(styles.wrapper, className)} style={{ '--avatar-with-online-status-size': size } as React.CSSProperties}>
			<div className={styles.avatarContainer}>
				{!avatarFallback && <img src={src} alt={name} className={imgClassName} />}
				{!!avatarFallback && avatarFallback}
			</div>

			{children}
		</div>
	);
};

export default Avatar;
