import { CLIENT_DISABLED_FIELDS_NAMES_LIST, CLIENT_PHONE_ASSOCIATED_OPTION_LIST, SEGMENTS_WITH_SPECIALIZATION } from 'const';
import type { BaseClientFormValues, ClientEditProhibitedFieldNames, IndividualClientFormValues, LegalClientFormValues } from 'models/IClient';

// === CLIENT FORM INITIAL STATE
const baseClientFormValues: BaseClientFormValues = {
	address: '',
	comment: { plainText: '', html: '' },
	contract: null,
	email: '',
	name: '',
	phones: [{ number: '', type: CLIENT_PHONE_ASSOCIATED_OPTION_LIST[0], priority: '' }],
	attachments: [],
	segment: null,
};

export const legalClientFormInitialValues: LegalClientFormValues = {
	...baseClientFormValues,
	organizationName: '',
	edrpouCode: '',
};

export const individualClientFormInitialValues: IndividualClientFormValues = {
	...baseClientFormValues,
	passport: '',
	ipnCode: '',
	attachments: [],
};

export const getClientFormInitialValues = (type: string, segmentName: string): IndividualClientFormValues | LegalClientFormValues => {
	const isLegalClient = type === 'legal';

	const hasSpecialization = checkIfHasSpecialization(segmentName);
	const defaultValues = isLegalClient ? legalClientFormInitialValues : individualClientFormInitialValues;

	if (hasSpecialization) {
		defaultValues['activities'] = [];
	}

	return defaultValues;
};

export const checkIfFieldIsBlacklistedForEdit = (name: keyof BaseClientFormValues) => {
	return true || CLIENT_DISABLED_FIELDS_NAMES_LIST.includes(name as ClientEditProhibitedFieldNames);
};

export const checkIfHasSpecialization = (segmentName: string): boolean => {
	return SEGMENTS_WITH_SPECIALIZATION.includes(segmentName);
};
