import clsx from 'clsx';
import NavLink from 'components/NavLink';
import { breakPoints, ROUTES_URLS } from 'const';
import React, { MouseEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'static/images/arrow-left-big.svg';
import { ReactComponent as ArrowRight } from 'static/images/chevron-right.svg';
import { ReactComponent as Sorting } from 'static/images/filter-lines.svg';
import { ReactComponent as HomeIcon } from 'static/images/home-line.svg';
import { isObject, isString } from 'utils/type-guards';

import styles from './style.module.css';
import { Breadcrumb, IProps } from './types';

const BreadCrumbs: React.FC<IProps> = ({ className, excludeRoot = false, crumbs, backLink, children, backLinkContent, onGoBack, onClickFilter }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const crumbsArr = crumbs.filter(({ asBackLinkCrumbOnly }) => !asBackLinkCrumbOnly);
	const lastCrumbIndex = crumbsArr.length - 1;

	const goPrevLocation = (event: MouseEvent<HTMLAnchorElement>) => {
		if (onGoBack) {
			return onGoBack(event);
		}

		event.preventDefault();

		if (backLink) {
			navigate(backLink);
		} else {
			if (location.state && isObject(location.state) && 'url' in location.state) {
				navigate(location.state.url);
			} else {
				navigate(-1);
			}
		}
	};

	const transformText = (crumb: Breadcrumb) => {
		if (!isString(crumb.label)) return crumb.label;

		return crumb.label.split(/(\d+\.\d+|\d+)/g).map((part, index) => {
			if (/\d+/.test(part)) {
				return <span key={index}>{part}</span>;
			} else {
				return part;
			}
		});
	};

	const handleLastBreadcrumbClick = (shouldPrevent: boolean) => (event: MouseEvent<HTMLAnchorElement>) => {
		if (shouldPrevent) {
			event.preventDefault();
		}
	};

	const backLinkCrumb = crumbs.find(({ asBackLinkCrumb, asBackLinkCrumbOnly }) => !!asBackLinkCrumbOnly || !!asBackLinkCrumb);

	return (
		<div className={clsx(styles.breadcrumbs, className)}>
			<>
				<div className={styles.back}>
					<NavLink to={backLink} onClick={goPrevLocation} className={styles.arrowBack}>
						<ArrowLeft />
					</NavLink>

					{!!backLinkContent && backLinkContent}
					{!backLinkContent && <h3 className={styles.backLinkTitle}>{transformText(backLinkCrumb || crumbs[lastCrumbIndex])}</h3>}
				</div>
				{children}
			</>
			{isMobile ? (
				onClickFilter && (
					<div className={styles.sortBtn} onClick={(e) => onClickFilter(e)}>
						<Sorting />
					</div>
				)
			) : (
				<div className={styles.crumbsWrapper}>
					{!excludeRoot && (
						<NavLink data-bread-crumb-link className={styles.navLink} to={ROUTES_URLS.HOME} data-href={ROUTES_URLS.HOME}>
							<HomeIcon />
						</NavLink>
					)}

					{crumbsArr.map(({ href, label }, index) => {
						const isLastCrumb = index === lastCrumbIndex;
						const targetIndex = excludeRoot ? 1 : 0;

						return (
							<div key={index} className={styles.breadcrumbLinkWrapper}>
								{index >= targetIndex && <ArrowRight className={styles.arrowPadding} />}

								<NavLink
									data-bread-crumb-link
									data-href={href}
									to={href}
									className={styles.breadcrumbLink}
									onClick={handleLastBreadcrumbClick(isLastCrumb)}
									data-disabled={isLastCrumb}
								>
									<span className={clsx('text-sm-medium', { [styles.lastCrumb]: isLastCrumb })}>{label}</span>
								</NavLink>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default BreadCrumbs;
