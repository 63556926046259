import clsx from 'clsx';
import type { IndividualClientFormField, LegalClientFormField } from 'models/IClientFormFields';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { checkIfHasSpecialization } from 'utils/forms';

import FieldGroup from './FieldGroup';
import { individualClientFormFields, legalClientFormFields, specializationField } from './Inputs/clientFormInputs';
import styles from './styles.module.css';
import type { CreateClientFormProps } from './types';

const HIDDEN_FIELD = ['contract', 'status'];

const ClientFormCreate: React.FC<CreateClientFormProps> = ({ onSubmit, clientType, segmentName }) => {
	const { handleSubmit } = useFormContext();
	const fields = clientType === 'individual' ? individualClientFormFields : legalClientFormFields;
	const hasSpecialization = checkIfHasSpecialization(segmentName);

	return (
		<form id="create-client-form" className={styles.clientForm} onSubmit={handleSubmit(onSubmit)}>
			{fields.map((fieldGroup: IndividualClientFormField[] | LegalClientFormField[], index: number) => {
				const children = fieldGroup
					.filter((field) => !HIDDEN_FIELD.includes(field.name))
					.map((field: IndividualClientFormField | LegalClientFormField, position, arr) => {
						const { Component, name: fieldName, id, createPlaceholder, ...props } = field;

						if (hasSpecialization && index === 0 && position === arr.length - 1) {
							const { Component: SpecializationField, id: specializationId, ...specializationFieldProps } = specializationField;

							return (
								<React.Fragment key={id + specializationId}>
									<Component {...props} name={fieldName} placeholder={createPlaceholder} />
									<SpecializationField {...specializationFieldProps} />
								</React.Fragment>
							);
						}

						return <Component key={id} {...props} name={fieldName} placeholder={createPlaceholder} />;
					});

				if (children.length === 0) return null;

				return <FieldGroup key={index}>{children}</FieldGroup>;
			})}
		</form>
	);
};

export default ClientFormCreate;

export function RichTextLabel({ title = 'Коментар', subTitle = 'Короткий опис про клієнта' }: { title?: string; subTitle?: string }) {
	return (
		<span className={styles.richTextLabelWrapper}>
			<span className={clsx('text-sm-medium', 'color-grey-700', styles.richTextLabel)}>{title}</span>
			<span className={clsx('text-sm-regular', 'color-grey-600', styles.richTextLabel)}>{subTitle}</span>
		</span>
	);
}
