export const clientErrorMessages = {
	organizationName: {
		required: 'Назва компанії обовʼязкова',
	},
	edrpouCode: {
		required: 'Код ЄДРПОУ обовʼязковий',
		minmax: 'Код ЄДРПОУ має місти 8 цифр',
		type: 'Мають бути лише цифри',
		notValid: 'Неправильний код ЄДРПОУ',
	},
	contract: {
		required: 'Тип контракта обовʼязковий',
	},
	facilities: {
		required: 'Інформація про обʼєкти обовʼязкова',
	},
	passport: {
		required: 'Номер паспорта обовʼязковий',
		notValid: 'Неправильний номер паспорта',
	},
	ipnCode: {
		required: 'ІПН є обовʼязковий',
		notValid: 'ІПН має містити 10 цифр',
	},
	status: {
		required: 'Стан клієнта є обовʼязковим',
	},
} as const;
