import { OptionSchema, ServerSideOptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import { z } from 'zod';

export const ByLimitMaxClientFilterSchema = z.number();
export const ByDaysOfDelayMaxClientFilterSchema = z.number();
export const ByClientsClientFilterSchema = z.object({ id: z.string(), name: z.string() });
export const ByManagerClientFilterSchema = standardizeOption<typeof OptionSchema, { '1c_uuid': string; name: string }>(OptionSchema, (manager) => ({
	label: manager.name,
	value: manager['1c_uuid'] ?? '',
}));

export const BySegmentClientFilterSchema = standardizeOption<typeof OptionSchema, { id: string; title: string }>(OptionSchema, (segment) => ({
	label: segment.title,
	value: segment.id,
}));
export const ByContractClientFilterSchema = z.string();

export const ClientFiltersSchema = z.object({
	limitMax: ByLimitMaxClientFilterSchema,
	daysOfDelayMax: ByDaysOfDelayMaxClientFilterSchema,
	clients: ByClientsClientFilterSchema.array(),
	responsible: ByManagerClientFilterSchema.array().optional(),
	managers: ByManagerClientFilterSchema.array().optional(),
	segments: BySegmentClientFilterSchema.array(),
	contracts: ByContractClientFilterSchema.array(),
	stocks: ServerSideOptionSchema.array().optional(),
});

export type ClientFilters = z.infer<typeof ClientFiltersSchema>;
